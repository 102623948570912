import { Component, OnInit } from '@angular/core';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  selectAuthLoading,
  selectIsSamlConfigured,
} from '~/app/auth/auth.selectors';
import { LoginService } from '~/app/auth/services/login.service';
import { LoginUser } from '~/app/auth/types/login.types';
import { ThemeService } from '~/app/core/services/theme.service';
import { AppState } from '~/app/core/state/app.state';
import { selectCurrentBreakpoint } from '~/app/core/state/breakpoint/breakpoint.selectors';
import * as AuthActions from '~/app/auth/auth.actions';
import { RegisterFormComponent } from '~/app/shared/components/register-form/register-form.component';
import { DialogService } from '~/app/shared/services/dialog.service';

/**
 * LoginFormComponent handles user login functionality, providing a form for inputting subdomain, email, and password,
 * and uses the LoginService to authenticate users based on these credentials.
 *
 *
 * @Component
 * @selector 'app-login-form' - CSS selector that identifies this component in a template.
 * @templateUrl './login-form.component.html' - Path to the HTML template for this component.
 * @styleUrls ['./login-form.component.scss'] - Paths to the stylesheets for this component.
 */
@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrl: './login-form.component.scss',
})
export class LoginFormComponent implements OnInit {
  /**
   * FormGroup that encapsulates the login form controls and validators.
   * @type {FormGroup}
   */
  loginForm!: FormGroup;

  /**
   * Observable that emits the loading state of the authentication process.
   * @type {Observable<boolean>}
   */
  isLoading$!: Observable<boolean>;

  /**
   * Observable that emits the current breakpoint of the application.
   * @type {Observable<string>}
   */
  breakpoint$!: Observable<string>;

  /**
   * Observable that emits whether SAML is configured for the current company.
   * @type {Observable<boolean>}
   */
  isSamlConfigured$: Observable<boolean>;

  /**
   * Constructor for LoginComponent.
   *
   * Initializes necessary services for building the form, handling user authentication, managing application state,
   * and applying themes dynamically.
   *
   * @param {FormBuilder} formBuilder - Manages form controls and validation.
   * @param {LoginService} loginService - Handles all logic related to user login.
   * @param {Store<AppState>} store - Provides access to Redux-style state management.
   * @param {ThemeService} theme - Allows dynamic theming across the application.
   */
  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private store: Store<AppState>,
    public theme: ThemeService,
    private dialog: DialogService
  ) {
    this.breakpoint$ = this.store.select(selectCurrentBreakpoint);
    this.isSamlConfigured$ = this.store.select(selectIsSamlConfigured);
    const companySubdomain = window.location.hostname.split('.')[0];
    this.store.dispatch(AuthActions.isSamlConfigured({ companySubdomain }));
  }

  /**
   * Getter for accessing the 'subdomain' FormControl within the login form.
   * @type {FormControl} FormControl for the subdomain input.
   */
  get subdomain(): FormControl {
    return this.loginForm.get('subdomain') as FormControl;
  }

  /**
   * Getter for accessing the 'email' FormControl within the login form.
   * @type {FormControl} FormControl for the email input.
   */
  get email(): FormControl {
    return this.loginForm.get('email') as FormControl;
  }

  /**
   * Getter for accessing the 'password' FormControl within the login form.
   * @type {FormControl} FormControl for the password input.
   */
  get password(): FormControl {
    return this.loginForm.get('password') as FormControl;
  }

  /**
   * OnInit lifecycle hook that initializes the form and subscribes to the authentication loading state.
   * @return {void}
   */
  ngOnInit(): void {
    this.initForm();
    this.isLoading$ = this.store.select(selectAuthLoading);
  }

  /**
   * Initializes the login form with default values and validators.
   * Sets up the form controls with required validators and specific constraints for each field.
   * @return {void}
   */
  onSubmit(): void {
    const loginData: LoginUser = {
      subdomain:
        window.location.hostname.split('.')[0] === 'localhost' ||
        window.location.hostname.split('.')[0] === 'ice-berry'
          ? this.loginForm.value.email
          : window.location.hostname.split('.')[0],
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
    };

    this.loginService.login(loginData);
  }

  /**
   * Handles the SAML login process by redirecting the user to the SAML login page.
   *
   * @return {void}
   */
  onClick(): void {
    this.loginService.samlLogin();
  }

  /**
   * Toggles the dialog service to display the registration form.
   * @return {void}
   */
  switchToRegister(): void {
    this.dialog.toggleDialog();
    this.dialog.loadComponent(RegisterFormComponent, {
      centered: 'centered',
      showBackdrop: true,
      closable: true,
    });
    this.dialog.toggleDialog();
  }

  /**
   * Handles the form submission event. Constructs the login data object from the form values
   * and passes it to the LoginService for authentication.
   * @return {void}
   */
  private initForm(): void {
    this.loginForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.minLength(1),
          Validators.maxLength(100),
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
        ],
      ],
    });
  }
}
