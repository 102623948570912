<div class="flex justify-content-around flex-column mb-6 lg:flex-row lg:gap-6">
  <div class="flex flex-column gap-4-8 w-full">
    <div class="flex flex-column gap-3-5">
      <h4 i18n class="display-h4 color-dark-100 mx-0 mb-0 mt-4-8 md:m-0">
        Login
      </h4>
      <div class="flex align-items-center gap-3-5">
        <p i18n class="text-base color-dark-70 m-0 nobr">
          Don't have an organization?
        </p>
        <a
          i18n
          (click)="switchToRegister()"
          class="text-bold-base m-0 color-dark-100 cursor-pointer no-underline"
          >Register</a
        >
      </div>
    </div>

    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div>
        <ice-input
          [attr.data-cy]="'email'"
          [label]="'Email'"
          [formControlName]="'email'"
          [formControl]="email">
        </ice-input>
        <ice-password
          [attr.data-cy]="'password'"
          [label]="'Password'"
          [formControlName]="'password'"
          [formControl]="password">
        </ice-password>

        <div class="flex align-items-center justify-content-between mb-6">
          <a
            i18n
            routerLink="/forgot-password"
            class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
            >Forgot password?</a
          >
        </div>

        <ice-button
          [attr.data-cy]="'login-button'"
          [style]="{ width: '100%' }"
          label="GO !"
          i18n-label
          i18n-icon
          type="submit"
          [disabled]="!loginForm.valid"
          [loading]="(isLoading$ | async) ?? false" />
      </div>
    </form>
  </div>
  <ng-container *ngIf="isSamlConfigured$ | async">
    <p-divider
      class="lg:hidden"
      align="center"
      [layout]="
        (breakpoint$ | async) === 'lg' ||
        (breakpoint$ | async) === 'md' ||
        (breakpoint$ | async) === 'sm'
          ? 'horizontal'
          : 'vertical'
      ">
      <b class="block lg:hidden">Or</b>
    </p-divider>

    <div class="image-container relative">
      <img
        ngSrc="assets/login-background.webp"
        priority
        fill
        alt="SAML connection wallpaper"
        class="border-round hidden lg:flex" />
      <div
        class="saml align-items-start flex flex-column gap-4 w-full lg:max-w-30rem lg:min-w-20rem">
        <div
          class="z-custom notch w-10rem justify-content-center align-items-center bg-light-100-forever hidden lg:flex">
          <img
            src="assets/ama-logo.png"
            alt="AMA Logo"
            height="15"
            class="mb-1" />
        </div>
        <div
          class="z-custom flex flex-column gap-3-5 w-full h-full justify-content-between align-items-end">
          <div class="hidden lg:flex w-full h-1rem"></div>
          <div class="text-sm mt-3 flex-column hidden w-full lg:flex">
            <p class="text-base color-light-100-forever m-0">Member of</p>
            <p class="text-xl color-light-100-forever m-0">
              AMA European Consulting?
            </p>
          </div>
          <ice-button
            [attr.data-cy]="'login-button'"
            label="Azure Connect"
            i18n-label
            i18n-icon
            icon="pi pi-microsoft"
            iconPos="right"
            [loading]="(isLoading$ | async) ?? false"
            (onClick)="onClick()"
            class="azure-gradient w-full lg:w-fit" />
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div i18n class="flex justify-content-center gap-1 mb-custom">
  <img src="assets/ice-cube-logo.png" alt="Iceberry Logo" height="20" />
  <span class="color-dark-80 text-xs align-content-center">ICEBERRY</span>
</div>
