import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Progress } from '~/app/shared/interfaces/websockets/progress.interface';

/**
 * Service for managing the visibility and progress of the progress toast.
 * This service is provided at the root level.
 */
@Injectable({
  providedIn: 'root',
})
export class ProgressToastService {
  /**
   * The visibility subject.
   *
   * @type {BehaviorSubject<boolean>}
   * @memberof ProgressToastService
   */
  isVisibleSubject = new BehaviorSubject<boolean>(false);

  /**
   * The progress subject.
   *
   * @type {BehaviorSubject<Progress | null>}
   * @memberof ProgressToastService
   */
  progressSubject = new BehaviorSubject<Progress | null>(null);

  /**
   * The visibility of the progress toast.
   *
   * @type {Observable<boolean>}
   * @memberof ProgressToastService
   */
  public isVisible$: Observable<boolean> = this.isVisibleSubject.asObservable();

  /**
   * The progress of the progress toast.
   *
   * @type {Observable<Progress | null>}
   * @memberof ProgressToastService
   */
  public progress$: Observable<Progress | null> =
    this.progressSubject.asObservable();

  /**
   * Toggles the visibility of the progress toast.
   *
   * @returns {void}
   */
  toggleToast(): void {
    this.isVisibleSubject.next(!this.isVisibleSubject.value);
  }

  /**
   * Sets the progress of the progress toast.
   *
   * @param {Observable<Progress | null>} progress - The progress to set.
   * @returns {void}
   */
  setProgress(progress: Observable<Progress | null>): void {
    progress.subscribe(value => {
      this.progressSubject.next(value);
    });
  }

  /**
   * Sets the visibility of the progress toast.
   *
   * @param {boolean} visible - The visibility
   * @returns {void}
   */
  setToastVisibility(visible: boolean): void {
    this.isVisibleSubject.next(visible);
  }

  /**
   * Sets the progress of the progress toast and makes it visible.
   *
   * @param {Observable<Progress | null>} progress - The progress to set.
   * @returns {void}
   */
  loadProgressToast(progress: Observable<Progress | null>): void {
    this.setProgress(progress);
    this.setToastVisibility(true);
  }
}
