import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  releaseNoteFeature,
  releaseNoteBugFix,
  releaseNoteUpgrade,
} from '~/app/shared/const/release-note.const';
import { FeatureDemoService } from '~/app/shared/services/feature-demo.service';
import { DialogService } from '~/app/shared/services/dialog.service';

/**
 * Represents a component for displaying release notes in a modal dialog.
 * This component displays categorized release notes, such as bug fixes,
 * new features, and upgrades.
 *
 * @Component The decorator that provides metadata for the component, including
 * the selector, template URL, and style URLs.
 */
@Component({
  selector: 'app-release-note',
  templateUrl: './release-note.component.html',
  styleUrls: ['./release-note.component.scss'],
})
export class ReleaseNoteComponent {
  /**
   * Array containing the list of bug fixes from the release notes.
   * Imported from a constant file.
   *
   * @type {string[]}
   */
  bugFix = releaseNoteBugFix;

  /**
   * Array containing the list of new features from the release notes.
   * Imported from a constant file.
   *
   * @type {string[]}
   */
  features = releaseNoteFeature;

  /**
   * Array containing the list of upgrades from the release notes.
   * Imported from a constant file.
   *
   * @type {string[]}
   */
  upgrades = releaseNoteUpgrade;

  /**
   * Constructs the ReleaseNoteComponent with necessary dependencies.
   *
   * @param {Router} router Angular router for navigating to different routes.
   * @param {DialogService} dialog Dialog service for managing dialog components.
   * @param {FeatureDemoService} tourService Service for managing feature tours.
   */
  constructor(
    private router: Router,
    private dialog: DialogService,
    private tourService: FeatureDemoService
  ) {}

  /**
   * Closes the release note dialog and resets the tour state.
   * Navigates to the home page after closing the dialog.
   * @returns {void}
   */
  resetTourAndGoHome(): void {
    this.dialog.toggleDialog();
    this.tourService.resetTourState();
    void this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => {
        this.router
          .navigate(['/home'])
          .catch(error => {
            console.error('Navigation error:', error);
          })
          .catch(error => {
            console.error('Navigation error:', error);
          });
      });
  }
}
