import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '~/app/core/state/app.state';
import { selectCurrentBreakpoint } from '~/app/core/state/breakpoint/breakpoint.selectors';
import { DialogHostDirective } from '~/app/shared/directives/dialog-host.directive';
import { DialogService } from '~/app/shared/services/dialog.service';

/**
 * Component responsible for rendering a modal that dynamically adjusts based on the viewport's size and can host various forms or additional content.
 */
@Component({
  selector: 'app-ice-dialog',
  templateUrl: './ice-dialog.component.html',
  styleUrl: './ice-dialog.component.scss',
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('backdropAnimation', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', [animate('300ms ease-in')]),
      transition(':leave', [animate('300ms ease-out')]),
    ]),
    trigger('dialogAnimation', [
      state(
        'void',
        style({
          opacity: 0,
          transform: 'scale(0.9)',
        })
      ),
      state(
        'visible',
        style({
          opacity: 1,
          transform: 'scale(1)',
        })
      ),
      transition('void => visible', [animate('150ms ease-out')]),
      transition('visible => void', [animate('100ms ease-in')]),
    ]),
  ],
})
export class IceDialogComponent implements OnInit {
  /**
   * Controls whether the modal is displayed as a blocking modal.
   * @type {boolean}
   */
  @Input() isPhoneScreen = true;

  /**
   * Event emitted when the visibility of the dialog changes.
   * This allows parent components to respond to visibility changes in the dialog.
   * @type {EventEmitter<boolean>}
   */
  @Output() visibilityChange = new EventEmitter<boolean>();

  /**
   * Accesses the DialogHostDirective to manage dynamic content loading into the modal.
   */
  @ViewChild(DialogHostDirective, { static: true })

  /**
   * Accesses the DialogHostDirective to manage dynamic content loading into the modal.
   */
  dialogHost!: DialogHostDirective;

  /**
   * Observable that emits the current breakpoint of the application.
   * @type {Observable<string>}
   */
  breakpoint$!: Observable<string>;

  /**
   * Constructs the IceModalComponent with necessary dependencies.
   *
   * @param {DialogService} dialog - Service used to manage the state and content of the modal.
   * @param {Store<AppState>} store - Provides access to Redux-style state management.
   * @param {BreakpointObserver} breakpointObserver - Breakpoint observer
   */
  constructor(
    public dialog: DialogService,
    private store: Store<AppState>,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpoint$ = this.store.select(selectCurrentBreakpoint);
  }

  /**
   * Fetches the centered boolean from the dialog service, dynamically reflecting changes.
   * @return {boolean} - A boolean value
   */
  public get centered(): boolean {
    return this.dialog.isCentered;
  }

  /**
   * Fetches the dialog position from the dialog service, dynamically reflecting changes.
   * @return {{ top: string, right: string }} - An object containing the top and right position of the dialog.
   */
  get dialogPosition(): { top: string; right: string } {
    return this.dialog.dialogPosition;
  }

  /**
   * Fetches the closable boolean from the dialog service, dynamically reflecting changes.
   * @return {boolean} - A boolean value
   */
  get isDialogClosable(): boolean {
    return this.dialog.isDialogClosable;
  }

  /**
   * Fetches the backdrop visibility from the dialog service, dynamically reflecting changes.
   * @return {boolean} - A boolean value
   */
  get showBackdrop(): boolean | undefined {
    return this.dialog.isBackdropVisible; // Fetch backdrop visibility from the service
  }

  /**
   * Controls the visibility of the modal, using a getter to retrieve the state and a setter to modify it,
   * encapsulating the visibility logic within the modal service.
   *
   * @return {boolean} - A boolean value indicating whether the modal is visible (true) or hidden (false).
   */
  public get isVisible(): boolean {
    return this.dialog.dialogVisible;
  }

  /**
   * Setter for the visibility of the modal. It delegates the control of the modal's visibility
   * to the ModalService, allowing the modal to be shown or hidden as dictated by user interactions
   * or other application logic.
   *
   * @param {boolean} value - A boolean value indicating whether the modal should be visible (true) or hidden (false).
   */
  public set isVisible(value: boolean) {
    this.dialog.setDialogVisibility(value);
  }

  /**
   * Closes the dialog and emits the visibility change event.
   *
   * @return {void}
   */
  close(): void {
    this.isVisible = false;
    this.visibilityChange.emit(this.isVisible);
  }

  /**
   * Closes the dialog when the backdrop is clicked.
   * Prevents accidental closures by ignoring clicks on the dialog content itself.
   *
   * @return {void}
   */
  closeOnBackdropClick(): void {
    this.close();
  }

  /**
   * OnInit lifecycle hook to set up the modal's content.
   *
   * @return {void}
   */
  ngOnInit(): void {
    this.dialog.setViewContainerRef(this.dialogHost.viewContainerRef);
    // Dynamically update `isModaled` based on the current breakpoint
    this.breakpointObserver
      .observe(['(max-width: 768px)']) // Change condition based on your `md` breakpoint
      .subscribe(result => {
        this.isPhoneScreen = result.matches; // True for smaller screens, false for larger screens
      });
  }
}
