<div
  @backdropAnimation
  [ngClass]="{
    hidden: !isVisible,
    'flex-centered': centered,
    'no-backdrop': !showBackdrop,
    'dialog-backdrop': showBackdrop,
  }"
  class="align-items-end md:align-items-center w-full"
  (click)="closeOnBackdropClick()">
  <div
    [ngClass]="{ dialog: !isPhoneScreen, phoneDialog: isPhoneScreen }"
    (click)="$event.stopPropagation()"
    [style.top]="dialogPosition.top"
    [style.right]="dialogPosition.right"
    [@dialogAnimation]="isVisible ? 'visible' : 'void'"
    class="border-dark-10 border-1 shadow-2 w-fit"
    @dialogAnimation>
    <div class="dialog-header" [ngClass]="{ hidden: !isDialogClosable }">
      <ice-button [text]="true" (click)="close()"
        ><i class="pi pi-times"></i
      ></ice-button>
    </div>
    <div class="dialog-content">
      <ng-template appDialogHost></ng-template>
    </div>
  </div>
</div>
