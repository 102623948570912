<section class="custom-width p-4 flex m-auto pt-8">
  <ice-card>
    <h3 class="display-h3 color-dark-100">{{ termsConst.title }}</h3>
    <p class="text-base color-dark-70 m-0">{{ termsConst.version }}</p>

    <p
      class="text-base color-dark-100 m-0"
      [innerHTML]="termsConst.introduction"></p>
    <p
      class="text-base color-dark-100 m-0"
      [innerHTML]="termsConst.preamble"></p>
    <p
      class="text-base color-dark-100 m-0"
      [innerHTML]="termsConst.definitions"></p>
    <p
      class="text-base color-dark-100 m-0"
      [innerHTML]="termsConst.inscription"></p>
    <p
      class="text-base color-dark-100 m-0"
      [innerHTML]="termsConst.mandateBilling"></p>
    <p
      class="text-base color-dark-100 m-0"
      [innerHTML]="termsConst.servicesAdditional"></p>
    <p
      class="text-base color-dark-100 m-0"
      [innerHTML]="termsConst.paymentSystem"></p>
    <p
      class="text-base color-dark-100 m-0"
      [innerHTML]="termsConst.evaluationSystem"></p>
    <p
      class="text-base color-dark-100 m-0"
      [innerHTML]="termsConst.disputes"></p>
    <p
      class="text-base color-dark-100 m-0"
      [innerHTML]="termsConst.responsibilities"></p>
    <p
      class="text-base color-dark-100 m-0"
      [innerHTML]="termsConst.intellectualProperty"></p>
    <p
      class="text-base color-dark-100 m-0"
      [innerHTML]="termsConst.dataProtection"></p>
    <p
      class="text-base color-dark-100 m-0"
      [innerHTML]="termsConst.jurisdiction"></p>
  </ice-card>
</section>
