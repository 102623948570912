<div
  class="flex align-items-center border-gray-200 flex justify-content-between p-4">
  <div class="flex align-items-center gap-3-5">
    <a class="flex mobile-display cursor-pointer">
      <img
        src="assets/icons/ice-berry-logo.svg"
        alt="Image"
        i18n-alt
        height="30" />
    </a>
    <p-breadcrumb
      styleClass="border-0 ml-5"
      class="max-w-full desktop-display"
      [model]="(urlSegments$ | async)!">
      <ng-template pTemplate="item" let-item let-i="index" let-last="last">
        <ng-container *ngIf="item.route; else elseBlock">
          <a [routerLink]="item.route" class="p-menuitem-link">
            <p
              [ngClass]="{ 'last-breadcrumb-item': last }"
              class="text-2xl color-dark-100 font-semibold">
              {{ item.label }}
            </p>
          </a>
        </ng-container>
        <ng-template #elseBlock>
          <a
            [href]="item.url"
            class="no-underline"
            [ngClass]="{ 'last-breadcrumb-item': last }">
            <span class="color-dark-100">{{ item.label | lineClip: 20 }}</span>
          </a>
        </ng-template>
      </ng-template>
      <ng-template pTemplate="separator"> / </ng-template>
    </p-breadcrumb>
  </div>

  <div>
    <ng-container *ngIf="(user$ | async)?.user as user; else notConnected">
      <a
        i18n-aria-label="@@userMenuAriaLabel"
        aria-label="User menu"
        class="cursor-pointer"
        (click)="toggleProfileDialog($event)">
        <ice-avatar [avatarContent]="user"> </ice-avatar>
      </a>
    </ng-container>
    <ng-template #notConnected>
      <a
        i18n-aria-label="@@userMenuAriaLabel"
        aria-label="User menu"
        class="cursor-pointer"
        (click)="toggleProfileDialog($event)">
        <p-avatar size="large" shape="circle" icon="pi pi-user"></p-avatar>
      </a>
    </ng-template>
  </div>
</div>
