import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { selectCurrentUser } from '~/app/auth/auth.selectors';
import { LoginService } from '~/app/auth/services/login.service';
import { ThemeService } from '~/app/core/services/theme.service';
import { AppState } from '~/app/core/state/app.state';
import { AccountMenuComponent } from '~/app/shared/components/account-menu/account-menu.component';
import { AuthUser } from '~/app/shared/interfaces/auth/auth-user.interface';
import { QuickActionItem } from '~/app/shared/interfaces/navigation/quick-action/quick-action-item.interface';
import { DialogService } from '~/app/shared/services/dialog.service';
import { SidenavService } from '~/app/shared/services/sidenav.service';
import { selectQuickActions } from '~/app/shared/states/quick-actions/quick-actions.selectors';

/**
 * The PublicHeaderComponent is responsible for rendering the header of the application when the user is not authenticated.
 * It provides navigation links and actions for the user to interact with the application.
 */
@Component({
  selector: 'app-public-header',
  templateUrl: './public-header.component.html',
  styleUrl: './public-header.component.scss',
})
export class PublicHeaderComponent implements OnInit {
  /**
   * A boolean flag to determine the current theme setting.
   *
   * When set to true, the light theme is applied.
   * When set to false, the dark theme is applied.
   * @type {boolean}
   */
  public isLightTheme = true;

  /**
   * User currently logged-in.
   * @type {Observable<AuthUser | null>}
   */
  user$: Observable<AuthUser | null>;

  /**
   * Observable stream of QuickActionItem objects representing the quick actions available in the header.
   *
   * @type {Observable<QuickActionItem[]>}
   */
  headerActions$!: Observable<QuickActionItem[]>;

  /**
   * Observable stream of MenuItem objects representing the current URL path segments.
   * @type {Observable<MenuItem[]>}
   */
  public urlSegments$!: Observable<MenuItem[]>;

  /**
   * Constructor for HeaderComponent, setting up and initializing various services and state observables necessary for its operation.
   * This component serves as the main header for the application, providing navigation and quick actions.
   *
   * @param {LoginService} loginService - Service handling user authentication and logout procedures.
   * @param {ThemeService} theme - Service for managing the application's theme settings.
   * @param {Store<AppState>} store - Global state store of the application, used here to select and manage application-wide settings like quick actions.
   * @param {Router} router - Angular's Router for subscribing to and handling navigation changes.
   * @param {SidenavService} sidenavService - Service for managing the sidebar's state, content, and visibility within the application.
   * @param {DialogService} dialog - Service for managing the dialog's state, content, and visibility within the application.
   */
  constructor(
    private loginService: LoginService,
    private store: Store<AppState>,
    private router: Router,
    private sidenavService: SidenavService,
    private theme: ThemeService,
    private dialog: DialogService
  ) {
    this.headerActions$ = this.store.select(selectQuickActions);
    this.user$ = this.store.select(selectCurrentUser);
    this.isLightTheme = this.theme.isDarkThemeActive();
  }

  /**
   * Initializes the component, subscribing to navigation events to update the URL path segments displayed in the header.
   *
   * @return {void}
   */
  ngOnInit(): void {
    const urlSegments = this.router.url.split('/');
    if (urlSegments.length > 0) {
      this.sidenavService.changeCurrentModule(urlSegments[1]);
    }
  }

  /**
   * Logs the user out of the application using the LoginService.
   * @return {void}
   */
  logout(): void {
    this.loginService.logout();
  }

  /**
   * Toggles the application's theme between light and dark.
   * @return {void}
   */
  onThemeToggle(): void {
    const currentTheme = localStorage.getItem('app-theme') ?? 'light';
    const newTheme = currentTheme === 'light' ? 'dark' : 'light';
    document.body.setAttribute('app-theme', newTheme);
    localStorage.setItem('app-theme', newTheme);
    this.isLightTheme = newTheme === 'light';
    this.theme.initTheme();
  }

  /**
   * Toggles the visibility of the user profile dialog.
   * @param {MouseEvent} event - The mouse event that triggered the dialog toggle.
   * @return {void}
   */
  toggleProfileDialog(event: MouseEvent): void {
    const targetElement = event.currentTarget as HTMLElement;
    this.dialog.loadComponent(AccountMenuComponent, {
      centered: 'onRef',
      showBackdrop: false,
      refElement: targetElement,
    });
    this.dialog.toggleDialog();
  }
}
