import { Component, OnDestroy, Type } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { isKanban } from '@npmicedev/icemodule/lib/utils/regex/kanban';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { map } from 'rxjs/operators';
import { LoginService } from '~/app/auth/services/login.service';
import { selectCurrentUser } from '~/app/auth/auth.selectors';
import { AppState } from '~/app/core/state/app.state';
import { CuProfileComponent } from '~/app/main/crm/profile/profile-layout/cu-profile/cu-profile.component';
import { CudOfferComponent } from '~/app/main/jobhub/components/company-offer/cud-offer/cud-offer.component';
import { CuSalesComponent } from '~/app/main/sales/profile/components/sales-layout/u-sales/cu-sales.component';
import { AccountMenuComponent } from '~/app/shared/components/account-menu/account-menu.component';
import { AuthUser } from '~/app/shared/interfaces/auth/auth-user.interface';
import { ModuleItem } from '~/app/shared/interfaces/module/module-item.interface';
import { QuickActionItem } from '~/app/shared/interfaces/navigation/quick-action/quick-action-item.interface';
import { DialogService } from '~/app/shared/services/dialog.service';
import { SidenavFormService } from '~/app/shared/services/sidenav-form.service';
import { selectCurrentModule } from '~/app/shared/states/navigation/navigation.selector';
import { selectQuickActions } from '~/app/shared/states/quick-actions/quick-actions.selectors';
import { QuickActionService } from '~/app/shared/services/quick-action.service';
import { HeaderItemGroup } from '~/app/shared/interfaces/navigation/header-item-group.interface';
import { SidenavService } from '~/app/shared/services/sidenav.service';
import { CudKanbanComponent } from '~/app/main/kanban/components/kanban-home/cud-kanban/cud-kanban.component';
import { selectKanban } from '~/app/main/kanban/states/kanbans/kanbans.selectors';
import { CudShuffleListComponent } from '~/app/main/shuffle-list/components/cud-shuffle-list/cud-shuffle-list.component';
import { CudGoalComponent } from '~/app/main/stats/components/goals/components/cud-goal/cud-goal.component';

/**
 * HeaderComponent manages the upper navigation bar of the application, providing quick access to user profile,
 * settings, and thematic changes, along with quick actions for creating or modifying application-specific data.
 *
 * @Component decorator defines this class as an Angular component.
 * @selector 'app-header' - The CSS selector that represents this component in the template.
 * @templateUrl './header.component.html' - The path to the HTML template associated with this component.
 * @styleUrls './header.component.scss' - The styles for this component.
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy {
  /**
   * Observable stream of MenuItem objects representing the current URL path segments.
   * @type {Observable<MenuItem[]>}
   */
  public urlSegments$!: Observable<MenuItem[]>;

  /**
   * User currently logged-in.
   * @type {Observable<AuthUser | null>}
   */
  user$: Observable<AuthUser | null>;

  /**
   * Items to be displayed in the header. Each item can have sub-items and associated actions or navigation links.
   * @type {HeaderItemGroup[]}
   */
  items: HeaderItemGroup[] = [
    {
      label: 'Options',
      items: [
        {
          label: 'My profile',
          escape: false,
          icon: 'pi pi-user',
          iconClass: 'text-xl',
          action: (): void => {
            void this.router.navigateByUrl(`/profile`);
          },
        },
        {
          label: 'Settings',
          escape: false,
          icon: 'pi pi-cog',
          iconClass: 'text-xl',
        },
        {
          label: 'Theme',
          customContent: true,
        },
        {
          label: 'Logout',
          icon: 'pi pi-sign-out',
          action: (): void => {
            this.logout();
          },
        },
      ],
    },
  ];

  /**
   * Observable stream of QuickActionItem objects representing the quick actions available in the header.
   *
   * @type {Observable<QuickActionItem[]>}
   */
  headerActions$!: Observable<QuickActionItem[]>;

  /**
   * An observable that emits the current module item or null.
   * @type {Observable<ModuleItem | null>}
   */
  currentModule$: Observable<ModuleItem | null>;

  /**
   * An observable for the name of the kanban.
   * @type {Observable<string | undefined>}
   */
  kanbanName$!: Observable<string | undefined>;

  /**
   * An observable for the name of the kanban.
   * @type {Subscription}
   */
  private kanbanNameSubscription: Subscription = new Subscription();

  /**
   * A subject used to signal the destruction of the component.
   * @type {Subject<void>}
   * @private
   */
  private destroy$: Subject<void> = new Subject<void>();

  /**
   * Constructor for HeaderComponent, setting up and initializing various services and state observables necessary for its operation.
   * This component serves as the main header for the application, providing navigation and quick actions.
   *
   * @param {LoginService} loginService - Service handling user authentication and logout procedures.
   * @param {SidenavFormService} sidebarService - Service for managing the sidebar.
   * @param {DialogService} dialog - Service for managing dialogs.
   * @param {Store<AppState>} store - Global state store of the application, used here to select and manage application-wide settings like quick actions.
   * @param {QuickActionService} quickActionService - Service for managing quick actions.
   * @param {Router} router - Angular's Router for subscribing to and handling navigation changes.
   * @param {SidenavService} sidenavService - Service for managing the sidenav.
   */
  constructor(
    private loginService: LoginService,
    private sidebarService: SidenavFormService,
    private dialog: DialogService,
    private store: Store<AppState>,
    private quickActionService: QuickActionService,
    private router: Router,
    private sidenavService: SidenavService
  ) {
    this.headerActions$ = this.store.select(selectQuickActions);
    this.store.select(selectQuickActions);
    this.user$ = this.store.select(selectCurrentUser);

    this.currentModule$ = this.store.select(selectCurrentModule);

    this.currentModule$.pipe(takeUntil(this.destroy$)).subscribe(te => {
      if (te?.name === 'kanban') {
        this.kanbanName$ = this.store
          .select(selectKanban)
          .pipe(map(kanban => kanban?.name));

        this.kanbanNameSubscription = this.kanbanName$.subscribe();
      }
      this.quickActionService.setQuickActions(te?.name ?? '');
    });
  }

  /**
   * Lifecycle hook that is called when the component is destroyed to unsubscribe
   * observables.
   *
   * @return {void}
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.kanbanNameSubscription.unsubscribe();
  }

  /**
   * Logs the user out of the application using the LoginService.
   * @return {void}
   */
  logout(): void {
    this.loginService.logout();
  }

  /**
   * Toggles the visibility of the user profile dialog.
   * @param {MouseEvent} event - The mouse event that triggered the dialog toggle.
   * @return {void}
   */
  toggleProfileDialog(event: MouseEvent): void {
    const targetElement = event.currentTarget as HTMLElement;
    this.dialog.loadComponent(AccountMenuComponent, {
      centered: 'onRef',
      showBackdrop: false,
      refElement: targetElement,
    });
    this.dialog.toggleDialog();
  }

  /**
   * Triggers the specified quick action.
   *
   * @param {QuickActionItem} quickAction - The quick action to trigger.
   * @returns {void}
   */
  triggerQuickAction(quickAction: QuickActionItem): void {
    switch (quickAction.name) {
      case 'Profile':
        this.loadSidebarComponent(CuProfileComponent, quickAction);
        break;
      case 'Sale':
        this.loadSidebarComponent(CuSalesComponent, quickAction);
        break;
      case 'Offer':
        this.dialog.loadComponent(CudOfferComponent, {
          centered: 'centered',
          showBackdrop: true,
        });
        this.dialog.toggleDialog();
        //this.loadSidebarComponent(CudOfferComponent, quickAction);
        break;
      case 'Kanban':
        const urlSplit = this.router.url.split('/');

        if (urlSplit.length > 2 && urlSplit[2].match(isKanban)) {
          const newQuickAction = {
            icon: quickAction.icon,
            name: 'Kanban Column',
            tooltip: quickAction.tooltip,
          };
          this.loadSidebarComponent(CudKanbanComponent, newQuickAction);
        } else {
          this.loadSidebarComponent(CudKanbanComponent, quickAction);
        }
        break;
      case 'Shuffle-list':
        this.loadSidebarComponent(CudShuffleListComponent, quickAction);
        break;
      case 'Goals':
        this.loadSidebarComponent(CudGoalComponent, quickAction);
    }
  }

  /**
   * Loads the specified component into the sidebar and toggles the sidebar.
   *
   * @param {Type<any>} component - The component to load into the sidebar.
   * @param {QuickActionItem} quickAction - The quick action triggering the component load.
   * @returns {void}
   */
  loadSidebarComponent(
    component: Type<any>,
    quickAction: QuickActionItem
  ): void {
    this.sidebarService.loadComponent(
      component,
      {},
      quickAction.name,
      'Create'
    );
    this.sidebarService.toggleSidebar();
  }

  /**
   * Opens the mobile sidenav.
   * @return {void}
   */
  openMobileSidenav(): void {
    this.sidenavService.openMobileSidenav();
  }
}
