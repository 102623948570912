import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CountryCodes } from '~/app/shared/interfaces/country-codes.interface';
import { FormValues } from '~/app/shared/interfaces/generic/form-values.interface';
import { LabelValueInterface } from '~/app/shared/interfaces/generic/label-value.interface';

/**
 * Utility service providing a variety of helper functions used across the application.
 * Includes methods for processing form data, generating error messages based on form validation,
 * filtering and transforming data, and device detection.
 *
 * @Injectable marks the class as available to an injector for instantiation,
 * specifically providing the service in the root of the application.
 */
@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  /**
   * Compares initial and current form values and returns an object containing only the values that have changed.
   * @param {FormValues} initialFormValues - The initial values loaded into the form.
   * @param {FormGroup} form - The FormGroup instance containing current form values.
   * @returns {T} An object of type T with only the properties that have changed compared to the initial values.
   */
  public getModifiedValues<T>(
    initialFormValues: FormValues,
    form: FormGroup
  ): T {
    const currentFormValues = form.getRawValue();
    const changes: Partial<T> = {};
    Object.keys(currentFormValues).forEach(key => {
      if (currentFormValues[key] !== initialFormValues[key]) {
        changes[key as keyof T] = currentFormValues[key];
      }
    });
    return changes as T;
  }

  /**
   * Filters null, undefined, or empty string values from an object or array recursively.
   * Returns null for empty objects or arrays after filtering.
   * @param {null | undefined | string | any[] | FormValues} obj - The object or array to filter.
   * @returns {any} The filtered object or array.
   */
  public filterValues(
    obj: null | undefined | string | any[] | { [key: string]: any }
  ): any {
    if (obj === null || obj === undefined || obj === '') {
      return null;
    }

    if (Array.isArray(obj)) {
      return obj
        .map(item => this.filterValues(item))
        .filter(
          value =>
            value !== null &&
            !(typeof value === 'object' && Object.keys(value).length === 0)
        );
    }

    if (typeof obj === 'object') {
      const filteredObject: { [key: string]: any } = {};
      Object.keys(obj).forEach(key => {
        if (obj[key] instanceof File) {
          filteredObject[key] = obj[key];
        } else {
          const filteredValue = this.filterValues(obj[key]);
          if (filteredValue !== null) {
            filteredObject[key] = filteredValue;
          }
        }
      });

      // Check if the object is empty after filtering
      if (Object.keys(filteredObject).length === 0) {
        return null;
      }

      return filteredObject;
    }

    return obj;
  }

  /**
   * Replaces empty string values in an object with null.
   * @param { FormValues } obj - The object to process.
   * @returns {object} The new object with empty strings replaced by null.
   */
  public replaceEmptyByNull(obj: { [key: string]: any }): object {
    const newObj: { [key: string]: any } = {};
    Object.keys(obj).forEach(key => {
      newObj[key] = obj[key] === '' ? null : obj[key];
    });
    return newObj;
  }

  /**
   * Converts a JavaScript object into FormData.
   * @param { FormValues } obj - The object to convert.
   * @returns {FormData} The resulting FormData object.
   */
  public createFormData(obj: { [key: string]: any }): FormData {
    const formData = new FormData();
    Object.keys(obj).forEach(key => {
      formData.append(key, obj[key]);
    });
    return formData;
  }

  /**
   * Finds a country object in an array by its code.
   * @param {CountryCodes[]} countries - The array of country codes.
   * @param {string} code - The country code to search for.
   * @returns {CountryCodes | object} The country object or an empty object if not found.
   */
  public findCountryByCode(
    countries: CountryCodes[],
    code: string
  ): CountryCodes | object {
    return countries.find(country => country.code === code) ?? {};
  }

  /**
   * Detects if the current device is a mobile device.
   * @returns {boolean} True if the device is a mobile device, false otherwise.
   */
  public isMobileDevice(): boolean {
    return (
      /iPad|iPhone|iPod/.test(navigator.userAgent) ||
      /(android)/i.test(navigator.userAgent)
    );
  }

  /**
   * Extracts values from fields, handling arrays of LabelValueType or plain strings.
   * @param {FormValues} fields - The fields from which to extract values.
   * @returns {FormValues} The extracted values.
   */
  public extractValue(fields: { [key: string]: any[] }): FormValues {
    const newObject = {} as { [key: string]: string[] };
    Object.keys(fields).forEach(field => {
      newObject[field] = fields[field];
    });

    return newObject;
  }

  /**
   * Checks if an array consists exclusively of `LabelValueType` objects or strings.
   * This method is used to determine if the provided field conforms to the `LabelValueType[]` type,
   * which is essential for certain processing in form management and data handling.
   *
   * A `LabelValueType` object is expected to have both `label` and `value` properties,
   * where `value` is a string and `label` is also a string.
   *
   * @param {any} field - The field to check, which could potentially be an array of `LabelValueType` objects or strings.
   * @returns {boolean} - Returns `true` if the field is an array and each item in the array is either a string
   * or an object with `label` and `value` properties as strings. Returns `false` otherwise.
   */
  private isLabelValueTypeArray(field: any): field is LabelValueInterface[] {
    if (!Array.isArray(field)) {
      return false;
    }

    return field.every(
      item =>
        (typeof item === 'object' &&
          item !== null &&
          'value' in item &&
          typeof item.value === 'string' &&
          'label' in item &&
          typeof item.label === 'string') ||
        typeof item === 'string'
    );
  }
}
