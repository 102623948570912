import {
  ApplicationInitStatus,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BaseSocketService } from '~/app/core/services/base-socket.service';
import { ThemeService } from '~/app/core/services/theme.service';
import { FeatureDemoService } from '~/app/shared/services/feature-demo.service';
/**
 * The root component of the Angular application.
 * Handles initialization tasks and global settings.
 *
 * @Component
 * @selector 'app-root' - CSS selector that identifies this component in a template.
 * @templateUrl './app.component.html' - Path to the HTML template for this component.
 * @styleUrls ['./app.component.scss'] - Paths to the stylesheets for this component.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  /**
   * String that contains the title of the application.
   * @type {string} - The title of the application.
   */
  title = 'IceDeed-front';

  /**
   * Constructs an instance of the AppComponent.
   *
   * @param {ApplicationInitStatus} appInit - The application initialization status.
   * @param {ChangeDetectorRef} cdRef - The change detector reference for manual change detection.
   * @param {Renderer2} renderer - The renderer for DOM manipulation.
   * @param {ThemeService} theme - The theme service for managing application themes.
   * @param {Meta} metaTagService - The meta tag service for managing HTML meta tags.
   * @param {SwUpdate} updates - The service worker update service for managing application updates.
   * @param {AlertService} alertService - The alert service for displaying alerts.
   * @param {FeatureDemoService} tourService - The feature demo service for managing feature tours.
   */
  constructor(
    public appInit: ApplicationInitStatus,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private theme: ThemeService,
    private metaTagService: Meta,
    private tourService: FeatureDemoService,
    private router: Router,
    private featureTourService: FeatureDemoService
  ) {
    this.theme.initTheme();
    this.metaTagService.addTags([
      {
        name: 'description',
        content:
          'IceBerry platform | IceBerry is a platform for managing your business.',
      },
      { property: 'og:title', content: 'IceBerry' },
      {
        property: 'og:description',
        content:
          'IceBerry platform | IceBerry is a platform for managing your business.',
      },
      {
        property: 'og:image',
        content: 'https://dev.ice-berry.com/assets/thumbnail.png',
      },
      {
        property: 'og:url',
        content: 'https://ice-berry.com',
      },
    ]);
  }

  /**
   * Event listener for the beforeunload event.
   * Prevents the user from leaving the page if a process is still running or a tour is active.
   *
   * @param {BeforeUnloadEvent} event - The beforeunload event.
   * @returns {void}
   */
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: BeforeUnloadEvent): void {
    if (
      BaseSocketService.isAnySocketRunning() ||
      this.featureTourService.isTourRunning()
    ) {
      event.preventDefault();
    }
  }

  /**
   * Initializes the component.
   * @returns {void}
   */
  ngOnInit(): void {
    void this.appInit.donePromise.then(() => {
      this.cdRef.detectChanges();
    });
  }
}
