import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { EmailSentComponent } from '~/app/_common-pages/email-sent/email-sent.component';
import { LandingComponent } from '~/app/landing/pages/landing/landing.component';
import { UnauthorizedComponent } from '~/app/_common-pages/unauthorized/unauthorized.component';
import { NotFoundComponent } from '~/app/_common-pages/not-found/not-found.component';
import { ForbiddenComponent } from '~/app/_common-pages/forbidden/forbidden.component';
import { authGuard } from '~/app/auth/guards/auth.guard';
import { RegisterSuccessComponent } from '~/app/_common-pages/register-success/register-success.component';
import { TermsComponent } from '~/app/landing/pages/terms/terms.component';
import { publicGuard } from '~/app/public/guards/public.guard';
import { TypographyComponent } from '~/app/shared/components/typography/typography.component';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
  },
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: '',
    loadChildren: () => import('./main/main.module').then(m => m.MainModule),
    canActivate: [authGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./public/public.module').then(m => m.PublicModule),
    canActivate: [publicGuard],
  },
  { path: 'terms', component: TermsComponent },
  { path: 'email-sent', component: EmailSentComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'not-found', component: NotFoundComponent },
  { path: 'forbidden', component: ForbiddenComponent },
  {
    path: 'register-success',
    component: RegisterSuccessComponent,
  },
  {
    path: 'typography',
    component: TypographyComponent,
    canActivate: [authGuard],
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
