import { createAction, props } from '@ngrx/store';
import { AllSearchClientCompanyFilters } from '~/app/shared/interfaces/client-company/all-search-client-company-filters.interface';
import { ProfileClientCompaniesState } from '~/app/main/crm/profile/states/client-companies/profile-client-companies.states';

export const loadAllClientCompanies = createAction(
  '[Profile Client Company] Load All Client Companies',
  props<{ filters: AllSearchClientCompanyFilters }>()
);
export const loadAllClientCompaniesSuccess = createAction(
  '[Profile Client Company] Load All Client Companies Success',
  props<{ allClientCompanies: ProfileClientCompaniesState }>()
);
export const loadAllClientCompaniesFailure = createAction(
  '[Profile Client Company] Load All Client Companies Failure',
  props<{ error: string }>()
);

export const clientCompanyUnauthorized = createAction(
  '[Profile Client Company] Unauthorized',
  props<{ error: string }>()
);
