<div
  class="flex align-items-center border-gray-200 flex justify-content-between py-4">
  <a class="flex mobile-display cursor-pointer" (click)="openMobileSidenav()">
    <img
      src="assets/icons/ice-berry-logo.svg"
      alt="Image"
      i18n-alt
      height="30"
      class="ml-3" />
  </a>
  <div class="flex justify-content-start align-items-center">
    <p-breadcrumb
      styleClass="border-0 ml-5"
      class="max-w-full desktop-display"
      [model]="(urlSegments$ | async)!">
      <ng-template pTemplate="item" let-item let-i="index" let-last="last">
        <ng-container *ngIf="item.route; else elseBlock">
          <a [routerLink]="item.route" class="p-menuitem-link">
            <p
              [ngClass]="{ 'last-breadcrumb-item': last }"
              class="text-2xl color-dark-100 font-semibold">
              {{ item.label }}
            </p>
          </a>
        </ng-container>
        <ng-template #elseBlock>
          <a
            [href]="item.url"
            class="no-underline"
            [ngClass]="{ 'last-breadcrumb-item': last }">
            <span class="color-dark-100">{{ item.label | lineClip: 20 }}</span>
          </a>
        </ng-template>
      </ng-template>
      <ng-template pTemplate="separator"> / </ng-template>
    </p-breadcrumb>

    <div *ngIf="kanbanName$ | async as Name">
      <span style="color: #334155; font-weight: 700; white-space: pre"
        >/&emsp;{{ Name }}</span
      >
    </div>
  </div>

  <div class="flex align-items-center gap-3">
    <div
      *ngFor="let quickAction of headerActions$ | async; let i = index"
      class="desktop-display">
      <ice-button
        [ngClass]="{
          'RELEASE-TOUR-STEP-3': i === 1,
          'RELEASE-TOUR-STEP-4': i === 0,
        }"
        (onClick)="triggerQuickAction(quickAction)"
        [pTooltip]="quickAction.tooltip"
        [tooltipPosition]="'bottom'"
        [icon]="quickAction.icon">
      </ice-button>
    </div>

    <ul
      i18n-aria-label="userProfileAndSettingsAriaLabel"
      aria-label="User profile and settings"
      class="list-none p-0 m-0 flex align-items-center flex-row border-none surface-border shadow-none static">
      <li class="RELEASE-TOUR-STEP-2 surface-border mx-4">
        <ng-container *ngIf="(user$ | async)?.user as user">
          <a
            i18n-aria-label="@@userMenuAriaLabel"
            aria-label="User menu"
            class="cursor-pointer"
            (click)="toggleProfileDialog($event)">
            <ice-avatar [size]="'medium'" [avatarContent]="user"></ice-avatar>
          </a>
        </ng-container>
      </li>
    </ul>
  </div>
</div>
<app-sidebar></app-sidebar>
