/**
 * Terms and conditions constants
 *
 * @description Terms and conditions constants
 * @type {object}
 * @property {string} title The title of the terms and conditions.
 * @property {string} version The version of the terms and conditions.
 * @property {string} introduction The introduction to the terms and conditions.
 * @property {string} preamble The preamble to the terms and conditions.
 * @property {string} definitions The definitions section of the terms and conditions.
 * @property {string} inscription The inscription section of the terms and conditions.
 * @property {string} services The services section of the terms and conditions.
 * @property {string} mandateBilling The mandate billing section of the terms and conditions.
 * @property {string} servicesAdditional The additional services section of the terms and conditions.
 * @property {string} paymentSystem The payment system section of the terms and conditions.
 * @property {string} evaluationSystem The evaluation system section of the terms and conditions.
 * @property {string} disputes The disputes section of the terms and conditions.
 * @property {string} responsibilities The responsibilities section of the terms and conditions.
 * @property {string} intellectualProperty The intellectual property section of the terms and conditions.
 * @property {string} dataProtection The data protection section of the terms and conditions.
 * @property {string} jurisdiction The jurisdiction section of the terms and conditions.
 * @default
 */
export const termsConst = {
  /**
   * The title of the terms and conditions.
   * @type {string}
   */
  title: 'Conditions Générales d’Utilisation et de Vente de IceBerry',

  /**
   * The version of the terms and conditions.
   * @type {string}
   */
  version: 'Version en vigueur à compter du 3 juillet 2024.',

  /**
   * The introduction to the terms and conditions.
   * @type {string}
   */
  introduction: `
    Les présentes conditions générales d’utilisation et de vente (ci-après dénommées « Conditions Générales ») sont conclues entre :
    <br><br>
    La société IceBerry Community, dont les éléments d’identification et coordonnées sont indiqués en Annexe du présent document (ci-après : la « Société »).
    <br><br>
    Et
    <br><br>
    L’Utilisateur de la Marketplace telle qu’indiquée en Annexe.
    <br><br>
    <b>Au 3 janvier 2025, le nombre d'utilisateurs s'élève à 1 704 000.</b>
  `,

  /**
   * The preamble to the terms and conditions.
   * @type {string}
   */
  preamble: `
    La Société exploite un site internet accessible à l’adresse indiquée en Annexe (ci-après la “Marketplace”), dédié à la mise en relation de Freelances et de Clients.
    <br><br>
    Les présentes Conditions Générales d’Utilisation et de Vente d’IceBerry Community SA (ci-après les “Conditions Générales”) ont pour objet de régir les conditions et modalités d’utilisation et de vente de la Marketplace, ainsi que de définir les droits et obligations des Utilisateurs mis en relation via la Marketplace.
    <br><br>
    Tout accès et/ou utilisation de la Marketplace suppose l’acceptation sans réserves et le respect de l’ensemble des termes des présentes Conditions Générales.
  `,

  /**
   * The definitions section of the terms and conditions.
   * @type {string}
   */
  definitions: `
    <h3>I – Définitions</h3>
    <ul>
      <li><b>Client</b> : désigne toute personne morale ou physique, utilisant la Marketplace à des fins strictement professionnelles, en vue d’entrer en contact ou d’être mis en relation avec un ou plusieurs Freelance(s) afin de lui confier la réalisation d’une Mission.</li>
      <li><b>Compte</b> : désigne le compte créé sur la Marketplace par l’Utilisateur lors de son inscription sur la Marketplace.</li>
      <li><b>Contenu</b> : désigne tous propos, messages ou informations de quelque nature que ce soit (texte, image, vidéos, photographies, commentaires, marques, dénominations sociales, etc.), mis en ligne par un Utilisateur sur la Marketplace.</li>
      <li><b>Devis</b> : désigne la proposition de services établie par le Freelance sur la Marketplace.</li>
      <li><b>Freelance</b> : désigne soit toute personne physique ou morale qui a enregistré une activité commerciale et est inscrite auprès du registre du commerce correspondant, soit exerçant en tant qu’indépendant dans des conditions légales depuis un pays où IceBerry possède une Société ou à l’étranger, et qui propose ses services aux Clients à travers la Marketplace.</li>
      <li><b>IceBerry Plus</b> : désigne le programme d’intelligence artificielle avancée créé par la Société, aux fins de recherche de Freelances répondant à des critères établis par le Client.</li>
      <li><b>IceBerry Strategy</b> : désigne l’offre de service additionnel qui inclut la recherche sur-mesure dans une base de données non publique contenant des profils de Freelances hautement qualifiés.</li>
      <li><b>Mandat de Facturation</b> : désigne le mandat de facturation conclu entre le Freelance et la Société, dont les modalités sont précisées à l’article 2.2.2 des Conditions Générales.</li>
      <li><b>Marketplace</b> : désigne le site internet exploité par la Société dont l’adresse est indiquée en Annexe.</li>
      <li><b>Mission</b> : désigne la tâche spécifique confiée par un Client via la Marketplace à un Freelance.</li>
      <li><b>Services</b> : désigne tous les services fournis par la Société tels que définis à l’article 3.1 des présentes.</li>
      <li><b>Société</b> : représente IceBerry Community et ses filiales qui exercent une activité commerciale permanente.</li>
      <li><b>Utilisateurs</b> : désigne les Freelances et les Clients inscrits sur la Marketplace.</li>
      <li><b>Validation</b> : désigne le processus défini à l’article 3.2.5 des Conditions Générales au terme duquel le Client déclare sur la Marketplace que la Mission exécutée par le Freelance est terminée et conforme au Devis.</li>
    </ul>
  `,

  /**
   * The inscription section of the terms and conditions.
   * @type {string}
   */
  inscription: `
    <h3>II – Inscription sur la Marketplace</h3>
    <h4>2.1 Conditions d'inscription sur la Marketplace des Utilisateurs</h4>
    Pour pouvoir accéder aux Services, l’Utilisateur doit créer un Compte en s’inscrivant sur la Marketplace.
    <br><br>
    L’Utilisateur doit être âgé d’au moins 18 ans et être capable juridiquement de contracter et d’utiliser la Marketplace conformément aux présentes Conditions Générales. L’Utilisateur est tenu de fournir des informations exactes qu’il s’engage à mettre immédiatement à jour en cas de modifications.
    <br><br>
    L’accès au Compte créé est protégé par un identifiant et un mot de passe choisi par l’Utilisateur lors de son inscription sur la Marketplace. L’Utilisateur est seul responsable de tout usage qui pourrait être fait de son identifiant et mot de passe, et seul garant de leur confidentialité, ainsi que de toute utilisation de son Compte.
  `,

  /**
   * The services section of the terms and conditions.
   * @type {string}
   */
  services: `
    <h3>III – Description des Services et fonctionnement de la Marketplace</h3>
    <h4>3.1. Description des Services</h4>
    <ul>
      <li>Création de Compte sur la Marketplace.</li>
      <li>Mise en relation entre Freelances et Clients via la Marketplace.</li>
      <li>Mise à disposition de moyens de paiement sécurisés.</li>
      <li>Établissement et émission de factures relatives aux Missions réalisées via la Marketplace.</li>
      <li>Outils de recommandation automatique de profils de Freelances.</li>
    </ul>
  `,

  /**
   * The mandate billing section of the terms and conditions.
   * @type {string}
   */
  mandateBilling: `
    <h4>2.2.2 Mandat de facturation</h4>
    Le Freelance reconnaît et accepte que la création d’un Compte sur la Marketplace emporte l’acceptation sans réserve de conclure un Mandat de Facturation avec la Société. 
    <br><br>
    En vertu du Mandat de Facturation, le Freelance, mandant, confie exclusivement à la Société, mandataire, (i) l'établissement et l'émission de ses factures pour les Missions réalisées via la Marketplace, et (ii) la collecte du règlement desdites factures, pour son compte.
    <br><br>
    Le Freelance accepte de télécharger sur la Marketplace les documents requis le concernant aux fins de satisfaire à ses obligations dans le cadre de la lutte contre le travail dissimulé, ainsi qu’aux règles de transparence (KYC) pour lutter contre la fraude fiscale, le blanchiment d’argent et le financement du terrorisme.
    <br><br>
    La Société demeure redevable de la TVA due, le cas échéant en application de la réglementation nationale, lorsque celle-ci est facturée à tort. 
    Le Mandat de Facturation prendra effet à compter de l'acceptation des Conditions Générales, et ce jusqu’à ce que le Freelance soit désinscrit du Site ou que la Société dénonce le Mandat.
  `,

  /**
   * The additional services section of the terms and conditions.
   * @type {string}
   */
  servicesAdditional: `
    <h4>3.1.2 Services Additionnels</h4>
    En complément des Services, les Clients peuvent bénéficier de services additionnels tels que :
    <ul>
      <li>Sourcing : Une prestation de recherche sur mesure par les équipes de la Société.</li>
      <li>Interface personnalisée : Une interface dédiée pour le Client et ses collaborateurs.</li>
      <li>Malt Insights : Un outil de gestion des données liées aux Missions, incluant alertes et suivi centralisé.</li>
      <li>IceBerry Strategy : Recherche de profils de Freelances hautement qualifiés.</li>
    </ul>
  `,

  /**
   * The payment system section of the terms and conditions.
   * @type {string}
   */
  paymentSystem: `
    <h4>VI – Système de paiement</h4>
    <h5>6.1 Paiement par provision</h5>
    Le paiement par le Client du montant de la Mission est effectué via un compte de monnaie électronique sécurisé (compte séquestre). Le paiement est réalisé par carte bancaire ou virement sécurisé.
    <br><br>
    Par exception, les Clients souscrivant à des Services Additionnels peuvent bénéficier de modalités de paiement spécifiques décrites à l’article 3.2.3.
    <br><br>
    La Société a souscrit aux services de la société Mangopay S.A., agréée en tant qu’établissement de monnaie électronique. Les Utilisateurs contractent directement avec MangoPay s’agissant du système de paiement par provision.
  `,

  /**
   * The evaluation system section of the terms and conditions.
   * @type {string}
   */
  evaluationSystem: `
    <h4>VII – Système d’évaluation et de recommandation</h4>
    À la fin de chaque Mission, les Clients et Freelances sont invités à évaluer mutuellement leur collaboration. Ces évaluations sont visibles par les autres Utilisateurs de la Marketplace selon les paramètres définis par la Société.
    <br><br>
    Les évaluations doivent rester objectives et respectueuses. Toute évaluation jugée inappropriée pourra être modérée ou supprimée par la Société.
  `,

  /**
   * The disputes section of the terms and conditions.
   * @type {string}
   */
  disputes: `
    <h4>VIII – Litiges</h4>
    <h5>8.1 Annulation d’un commun accord</h5>
    Si une Mission est annulée par accord mutuel entre le Client et le Freelance, le montant provisionné sera remboursé au Client dans un délai de 15 jours.
    <br><br>
    <h5>8.2 Différends</h5>
    En cas de désaccord sur une Mission, les parties doivent contacter le Service Client de la Société. Une tentative de médiation sera menée pendant une période maximale de 45 jours. Si aucune solution n’est trouvée, la Société se dessaisira du différend, et les parties devront résoudre le litige par elles-mêmes.
  `,

  /**
   * The responsibilities section of the terms and conditions.
   * @type {string}
   */
  responsibilities: `
    <h4>X – Responsabilité</h4>
    <h5>10.1 Responsabilité des Utilisateurs</h5>
    Chaque Utilisateur est responsable des informations qu’il fournit sur la Marketplace. Il s’engage à ne pas publier de contenu illicite, diffamatoire ou portant atteinte aux droits des tiers.
    <br><br>
    <h5>10.6 Responsabilité de la Société</h5>
    La Société met en œuvre tous les moyens pour assurer l’accès et le bon fonctionnement de la Marketplace. Toutefois, elle ne saurait être tenue responsable en cas d’interruptions dues à des maintenances, des dysfonctionnements techniques ou des cas de force majeure.
  `,

  /**
   * The intellectual property section of the terms and conditions.
   * @type {string}
   */
  intellectualProperty: `
    <h4>XIV – Propriété Intellectuelle</h4>
    <h5>14.1 Propriété de la Marketplace</h5>
    La Marketplace et ses éléments constitutifs (textes, images, logos, etc.) sont protégés par des droits de propriété intellectuelle et restent la propriété exclusive de la Société.
    <br><br>
    <h5>14.2 Propriété des Contenus</h5>
    Les Utilisateurs conservent la propriété de leurs Contenus publiés, mais accordent à la Société une licence d’utilisation mondiale et gratuite pour les besoins de promotion et d’amélioration des Services.
  `,

  /**
   * The data protection section of the terms and conditions.
   * @type {string}
   */
  dataProtection: `
    <h4>XII – Données personnelles</h4>
    La Société s’engage à respecter la confidentialité des données personnelles des Utilisateurs, conformément à sa politique de protection des données disponible à l’adresse indiquée en Annexe.
  `,

  /**
   * The jurisdiction section of the terms and conditions.
   * @type {string}
   */
  jurisdiction: `
    <h4>XXI – Droit applicable et juridiction compétente</h4>
    Les présentes Conditions Générales sont régies par le droit applicable précisé en Annexe. En cas de litige, la compétence revient exclusivement aux juridictions désignées.
  `,
};
