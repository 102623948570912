<div class="w-full">
  <div
    [formGroup]="navigationForm"
    class="container flex justify-content-between m-auto p-4 md:p-5 align-items-center">
    <h1 class="font-semibold md:w-20rem m-0">
      <a
        href="/IceDeed-front/src/app/public"
        class="no-underline flex align-items-center gap-3-5">
        <img
          [ngSrc]="
            'assets/icons/ice-berry-logo' +
            (isDarkTheme ? '-dark' : '') +
            '.svg'
          "
          alt="IceBerry Logo"
          height="30"
          width="30"
          i18n-alt />
        <p class="hidden md:block color-dark-100">IceBerry</p>
      </a>
    </h1>
    <div class="flex md:w-20rem justify-content-end">
      <ng-container
        *ngIf="(isLoggedIn$ | async)?.userData?.token; else loggedOut">
        <ice-button
          (onClick)="logout()"
          [text]="true"
          label="Logout"
          i18n-label
          class="font-bold"></ice-button>
        <ice-button
          label="IceBerry"
          [outlined]="true"
          i18n-label
          class="md:ml-3 font-bold"
          routerLink="/home"></ice-button>
      </ng-container>

      <!-- Logged out states -->
      <ng-template #loggedOut>
        <ice-button
          [attr.data-cy]="'login-button'"
          label="Login"
          i18n-label
          [text]="true"
          (onClick)="clickLogin()"
          class="font-bold"></ice-button>
        <ice-button
          [attr.data-cy]="'register-button'"
          label="Create my profile"
          [outlined]="true"
          i18n-label
          class="ml-3 font-bold"
          (onClick)="clickRegister()"></ice-button>
      </ng-template>
    </div>
  </div>
</div>
