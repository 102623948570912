<section class="flex flex-column gap-3-5 md:gap-0">
  <div class="flex gap-3-5 md:pr-6">
    <p-carousel
      class="hidden md:block"
      [value]="images"
      [showNavigators]="false"
      [numVisible]="1"
      [numScroll]="1"
      [showIndicators]="false"
      [autoplayInterval]="10000"
      [circular]="true"
      [style]="{ width: '28rem' }">
      <ng-template pTemplate="item" let-image>
        <img [src]="image" alt="Random image" />
      </ng-template>
    </p-carousel>

    <ng-container *ngIf="!isFormSubmitted; else formSubmitted">
      <div class="flex flex-column w-full justify-content-center gap-7-75">
        <div class="flex flex-column gap-3-5">
          <h4 class="display-h4 m-0 color-dark-70">
            Join <span class="gradiant">IceBerry</span>
          </h4>
          <p class="text-base color-dark-70 m-0">
            Already have an account?
            <a
              i18n
              (click)="switchToLogin()"
              class="text-bold-base m-0 color-dark-100 cursor-pointer no-underline"
              >Login</a
            >
          </p>
        </div>

        <form
          class="flex flex-column gap-7-75"
          [formGroup]="jobSeekerForm"
          (ngSubmit)="onSubmit()">
          <div>
            <div class="flex gap-3-5">
              <ice-input
                [label]="'First name'"
                [formControlName]="'firstname'"
                [formControl]="firstname"
                [required]="true"></ice-input>
              <ice-input
                [label]="'Last name'"
                [formControlName]="'lastname'"
                [formControl]="lastname"
                [required]="true"></ice-input>
            </div>
            <ice-input
              [label]="'Email'"
              [formControlName]="'userEmail'"
              [formControl]="userEmail"
              [required]="true"></ice-input>
            <ice-password
              [label]="'Password'"
              [formControlName]="'password'"
              [formControl]="password"
              [required]="true"></ice-password>
            <ice-password
              [label]="'Confirm password'"
              [formControlName]="'confirmPassword'"
              [formControl]="confirmPassword"
              [required]="true"></ice-password>
            <div class="flex mt-3-5 gap-3-5">
              <ice-checkbox
                [binary]="true"
                (onChange)="onTermsAndConditionsChange($event)"></ice-checkbox>
              <span class="text-sm color-dark-30">
                I agree to the
                <a href="/terms" target="_blank" class="text-blue-500"
                  >terms and conditions</a
                >
              </span>
            </div>
          </div>

          <div class="flex justify-content-end w-full">
            <ice-button
              [label]="
                jobSeekerForm.valid
                  ? 'Go find my dream job noowww !!'
                  : 'Register'
              "
              [fullWidth]="true"
              type="submit"
              [disabled]="!jobSeekerForm.valid"></ice-button>
          </div>
        </form>
      </div>
    </ng-container>
    <ng-template #formSubmitted>
      <div
        class="flex flex-column w-full align-items-center justify-content-center gap-7-75">
        <div class="flex flex-column gap-3-5">
          <h4 class="display-h4 m-0 mt-3-5 md:mt-0 color-dark-70">
            You can check your mailbox.
          </h4>
          <p class="text-base color-dark-70 m-0">
            You have received an email at
            <span class="font-semibold">{{ userEmail.value }}</span
            >.<br />
            Please check your mailbox and click the link to verify your account.
          </p>
        </div>
        <div
          class="flex flex-column align-items-center gap-3-5 w-full md:w-auto">
          <ice-button
            [label]="'Back to register'"
            [icon]="'pi pi-arrow-left'"
            [fullWidth]="true"
            (click)="isFormSubmitted = false"
            [text]="true"></ice-button>
        </div>
      </div>
    </ng-template>
  </div>
  <footer i18n class="flex justify-content-center gap-1 mb-custom">
    <img src="assets/ice-cube-logo.png" alt="Iceberry Logo" height="20" />
    <span class="color-dark-80 text-xs align-content-center">ICEBERRY</span>
  </footer>
</section>
