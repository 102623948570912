import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from '~/app/auth/auth.state';
import { AuthUser } from '~/app/shared/interfaces/auth/auth-user.interface';

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectCurrentUser = createSelector(
  selectAuthState,
  (state: AuthState) => state.userData
);

export const selectCurrentCompany = createSelector(
  selectCurrentUser,
  state => state?.company ?? null
);

export const selectIsRootUser = createSelector(
  selectCurrentUser,
  state => state?.user.isRoot ?? false
);

export const selectCurrentRules = createSelector(
  selectCurrentUser,
  state => state?.rules
);

export const selectAuthLoading = createSelector(
  selectAuthState,
  (state: AuthState) => state.loading
);

export const selectAuthUserTeam = createSelector(
  selectCurrentUser,
  state => state?.teams ?? []
);

export const selectCredits = createSelector(
  selectCurrentCompany,
  state => state?.credits
);

export const selectSecureLoginToken = createSelector(
  selectAuthState,
  state => state.secureLoginToken
);

export const selectToken = createSelector(
  selectCurrentUser,
  (user: AuthUser | null) => user?.token
);

export const selectServerToken = createSelector(
  selectCurrentUser,
  (user: AuthUser | null) => user?.serverToken
);

export const selectLastRequest = createSelector(
  selectAuthState,
  state => state.lastRequest
);

export const selectIsSamlConfigured = createSelector(
  selectAuthState,
  state => state.isSamlConfigured
);
