import { TourStep } from '~/app/shared/interfaces/generic/tour-step.interface';

/**
 * Contains the release notes for bug fixes as an array of strings.
 *
 * @constant
 * @type {string[]}
 */
export const releaseNoteBugFix = [
  'Correction of a bug in client companies during the transition of a candidate to a consultant.',
  'Fixed a bug preventing the use of AI search.',
];

/**
 * Contains the release notes for upgrades as an array of strings.
 *
 * @constant
 * @type {string[]}
 */
export const releaseNoteUpgrade = [
  'Create offer form: The form is now in a modal dialog for a better user experience.',
  'Revised navigation bar behavior with added internal scrolling.',
  'Improved TJ distribution graph in search profiles.',
  'Enhanced server performance.',
  'Graphical improvements in search profiles and sales.',
];

/**
 * Contains the release notes for features as an array of strings.
 *
 * @constant
 * @type {string[]}
 */
export const releaseNoteFeature = [
  'Complete overhaul of Kanban module with real-time updates from other users!',
  'Added the ability to create an offer in the JobHub module.',
  'Introduced an option for freelancers to log in to the platform.',
  'Added the "my-profile" page.',
  'Integrated a bug report button in the navigation bar.',
  'Added a "terms and conditions" page.',
  'Enhanced profile creation flow with AI.',
  'Added the ability to assign multiple profiles to a Kanban card.',
];

/**
 * Contains the steps for the release notes tutorial tour.
 *
 * @constant
 * @type {Array}
 */
export const releaseNoteTourSteps: TourStep[] = [
  {
    id: 'step-1',
    attachTo: {
      element: '.RELEASE-TOUR-STEP-1',
      on: 'right' as const,
    },
    buttons: [
      {
        text: 'Next',
        action: 'next',
      },
    ],
    title: 'Bug report',
    text: ['Here you can report a bug by filling out the form.'],
  },
  {
    id: 'step-2',
    attachTo: {
      element: '.RELEASE-TOUR-STEP-2',
      on: 'left' as const,
    },
    buttons: [
      {
        text: 'Next',
        action: 'next',
      },
    ],
    title: 'My profile',
    text: ['You can now access to your profile settings.'],
  },
  {
    id: 'step-3',
    attachTo: {
      element: '.RELEASE-TOUR-STEP-3',
      on: 'left' as const,
    },
    buttons: [
      {
        text: 'Next',
        action: 'next',
      },
    ],
    title: 'Create profile',
    text: ['A simple CV drag & drop to create a new candidate.'],
  },
  {
    id: 'step-4',
    attachTo: {
      element: '.RELEASE-TOUR-STEP-4',
      on: 'left' as const,
    },
    buttons: [
      {
        text: 'Next',
        action: 'next',
      },
    ],
    title: 'Create a job offer',
    text: [
      'Not available yet. But you will be able to create a job offer soon.',
    ],
    route: 'jobhub/my-offers',
  },
  {
    id: 'step-5',
    attachTo: {
      element: '.RELEASE-TOUR-STEP-5',
      on: 'right' as const,
    },
    buttons: [
      {
        text: 'Next',
        action: 'next',
      },
    ],
    title: 'New filters',
    text: ['New filters have been added to the search.'],
    route: '/crm/search-candidates',
  },
  {
    id: 'step-6',
    attachTo: {
      element: '.RELEASE-TOUR-STEP-6',
      on: 'right' as const,
    },
    buttons: [
      {
        text: 'Next',
        action: 'next',
      },
    ],
    title: 'Salary range',
    text: ['Like this one'],
  },
  {
    id: 'step-7',
    attachTo: {
      element: '.RELEASE-TOUR-STEP-7',
      on: 'right' as const,
    },
    buttons: [
      {
        text: 'Finish',
        action: 'complete',
      },
    ],
    title: 'Berry board',
    text: [
      'A lot of new features have been added to the Berry board. Changes are now live. So you can see live updates on the board.',
    ],
    route: '/kanban',
  },
];
