/* eslint-disable max-lines */

import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { ProfileType } from '@npmicedev/icemodule/lib/enums/profileType';
import IceRule from '@npmicedev/icemodule/lib/utils/rules';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { Observable, skipWhile, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '~/app/core/state/app.state';
import {
  selectProfileExperiences,
  selectProfileTypes,
} from '~/app/core/state/enum/enums.selectors';
import { ProfileService } from '~/app/main/crm/profile/services/profile.service';
import { Profile } from '~/app/shared/interfaces/crm/profile/profile.interface';
import { Status } from '~/app/shared/interfaces/shared/status.interface';
import { DataService } from '~/app/shared/services/data.service';
import { UtilsService } from '~/app/shared/services/utils.service';
import { iso31661Codes } from '~/app/shared/const/country-code.const';
import { IceAutocompleteTemplateEnum } from '~/app/shared/enums/ice-autocomplete-template.enum';
import { LabelValueInterface } from '~/app/shared/interfaces/generic/label-value.interface';
import { ProfileState } from '~/app/main/crm/profile/profile-layout/state/profile.state';
import { selectProfileLoadingState } from '~/app/main/crm/profile/profile-layout/state/profile.selectors';
import { selectProfileClientCompanies } from '~/app/main/crm/profile/states/client-companies/profile-client-compagnies.selectors';
import { ProfileClientCompanyService } from '~/app/main/crm/profile/services/profile-client-compagnies.service';

/**
 * Component for creating and updating profiles manually.
 *
 * @Component decorator provides metadata for the component.
 * @selector 'app-cu-profile-manual' – CSS selector that defines how the component will be used in templates.
 * @templateUrl './cu-profile-manual.component.html' – Path to the HTML template associated with this component.
 * @styleUrls ['./cu-profile-manual.component.scss'] – Array of paths to the stylesheets used for this component.
 */
@Component({
  selector: 'app-cu-profile-manual',
  templateUrl: './cu-profile-manual.component.html',
  styleUrls: ['./cu-profile-manual.component.scss'],
})
export class CuProfileManualComponent implements OnInit {
  /**
   * The initial profile data for editing.
   * @type {Profile | null}
   */
  @Input() initialData: Profile | null = null;

  /**
   * The form group for the profile form.
   * @type {FormGroup}
   */
  profileForm!: FormGroup;

  /**
   * Observable emitting a list of offices.
   * @type {Observable<LabelValueInterface[]>}
   */
  offices$: Observable<LabelValueInterface[]>;

  /**
   * Observable emitting a list of teams.
   * @type {Observable<LabelValueInterface[]>}
   */
  teams$: Observable<LabelValueInterface[]>;

  /**
   * Observable emitting a list of profile experiences.
   * @type {Observable<LabelValueInterface[]>}
   */
  experiences$: Observable<LabelValueInterface[]>;

  /**
   * Observable emitting a list of statuses.
   * @type {Observable<LabelValueInterface[]>}
   */
  statuses$: Observable<LabelValueInterface[]>;

  /**
   * Observable emitting a list of client companies.
   * @type {Observable<LabelValueInterface[]>}
   */
  clientCompanies$: Observable<LabelValueInterface[]>;

  /**
   * Observable for available profile types.
   */
  profileTypes$: Observable<LabelValueInterface[]>;

  /**
   * Observable for the loading state of the cud profile.
   *
   * @type {Observable<boolean>}
   */
  isProfileLoading$: Observable<boolean>;

  /**
   * IceRule utility for handling rules.
   * @type {typeof IceRule}
   * @protected
   */
  protected readonly iceRule = IceRule;

  /**
   * Enum for IceAutocomplete templates.
   * @type {typeof IceAutocompleteTemplateEnum}
   * @protected
   */
  protected readonly templateEnum = IceAutocompleteTemplateEnum;

  /**
   * Enum for profile types.
   * @type {typeof ProfileType}
   * @protected
   */
  protected readonly profileType = ProfileType;

  /**
   * The date of the day
   * @type {Date}
   * @protected
   */
  protected readonly today = new Date();

  /**
   * Constructs an instance of the CuProfileManualComponent.
   *
   * @param {ProfileService} profile - Injectable service for managing profiles.
   * @param {FormBuilder} formBuilder - Injectable form builder for creating forms.
   * @param {Store<AppState>} appStore - Injectable store for managing application state.
   * @param {DatePipe} datePipe - Injectable date pipe for formatting dates.
   * @param {UtilsService} utils - Injectable utility service for various helper functions.
   * @param {DataService} data - Injectable service for accessing data.
   * @param {SearchClientCompanyService} clientCompaniesService - Injectable service for managing client companies.
   * @param {Store<ProfileState>} profileStore - Store for managing the profile state.
   */
  constructor(
    private profile: ProfileService,
    private formBuilder: FormBuilder,
    private appStore: Store<AppState>,
    private datePipe: DatePipe,
    private utils: UtilsService,
    private data: DataService,
    private clientCompaniesService: ProfileClientCompanyService,
    private profileStore: Store<ProfileState>
  ) {
    this.experiences$ = this.appStore.select(selectProfileExperiences);
    this.profileTypes$ = this.appStore.select(selectProfileTypes);

    this.isProfileLoading$ = this.profileStore.select(
      selectProfileLoadingState
    );

    this.offices$ = this.data.offices$.pipe(
      skipWhile(items => !items),
      take(2),
      map(offices =>
        offices.map(office => ({ label: office.name, value: office.uuid }))
      )
    );

    this.teams$ = this.data.teams$.pipe(
      skipWhile(items => !items),
      take(2),
      map(teams =>
        teams.map(team => ({
          label: team.name,
          value: team.uuid,
        }))
      )
    );

    this.clientCompanies$ = this.profileStore
      .select(selectProfileClientCompanies)
      .pipe(
        skipWhile(items => !items),
        map(clientCompanies =>
          clientCompanies.map(clientCompany => ({
            label: clientCompany.name,
            value: clientCompany.uuid,
          }))
        )
      );

    this.statuses$ = this.data.candidateStatuses$.pipe(
      skipWhile(items => !items),
      take(2),
      map(candidateStatuses =>
        candidateStatuses.map((status: Status) => ({
          label: status.name,
          value: status.uuid,
          color: status.color,
        }))
      )
    );
  }

  /**
   * Gets the firstname form control.
   * @returns {FormControl} The firstname form control.
   */
  public get firstname(): FormControl {
    return this.profileForm.get('firstname') as FormControl;
  }

  /**
   * Gets the lastname form control.
   * @returns {FormControl} The lastname form control.
   */
  public get lastname(): FormControl {
    return this.profileForm.get('lastname') as FormControl;
  }

  /**
   * Gets the birthday form control.
   * @returns {FormControl} The birthday form control.
   */
  public get birthday(): FormControl {
    return this.profileForm.get('birthday') as FormControl;
  }

  /**
   * Gets the value of the birthday form control.
   * @returns {string} The birthday value.
   */
  public get birthdayValue(): string {
    return this.birthday.value;
  }

  /**
   * Gets the phone form control.
   * @returns {FormControl} The phone form control.
   */
  public get phone(): FormControl {
    return this.profileForm.get('phone') as FormControl;
  }

  /**
   * Gets the phoneIso form control.
   * @returns {FormControl} The phoneIso form control.
   */
  public get phoneIso(): FormControl {
    return this.profileForm.get('phoneIso') as FormControl;
  }

  /**
   * Gets the email form control.
   * @returns {FormControl} The email form control.
   */
  public get email(): FormControl {
    return this.profileForm.get('email') as FormControl;
  }

  /**
   * Gets the type form control.
   * @returns {FormControl} The type form control.
   */
  public get type(): FormControl {
    return this.profileForm.get('type') as FormControl;
  }

  /**
   * Gets the experience form control.
   * @returns {FormControl} The experience form control.
   */
  public get experience(): FormControl {
    return this.profileForm.get('experience') as FormControl;
  }

  /**
   * Gets the value of the experience form control.
   * @returns {string} The experience value.
   */
  public get experienceValue(): FormControl {
    return this.experience.value?.value ?? this.experience.value;
  }

  /**
   * Gets the freelanceSalary form control.
   * @returns {FormControl} The freelanceSalary form control.
   */
  public get freelanceSalary(): FormControl {
    return this.profileForm.get('freelanceSalary') as FormControl;
  }

  /**
   * Gets the office form control.
   * @returns {FormControl} The office form control.
   */
  public get officeId(): FormControl {
    return this.profileForm.get('officeId') as FormControl;
  }

  /**
   * Gets the value of the office form control.
   * @returns {string} The office value.
   */
  public get officeValue(): string {
    return this.officeId.value?.value ?? this.officeId.value;
  }

  /**
   * Gets the team form control.
   * @returns {FormControl} The team form control.
   */
  public get teamId(): FormControl {
    return this.profileForm.get('teamId') as FormControl;
  }

  /**
   * Gets the value of the team form control.
   * @returns {string} The team value.
   */
  public get teamValue(): string {
    return this.teamId.value?.value ?? this.teamId.value;
  }

  /**
   * Gets the status form control.
   * @returns {FormControl} The status form control.
   */
  public get statusId(): FormControl {
    return this.profileForm.get('statusId') as FormControl;
  }

  /**
   * Gets the value of the status form control.
   * @returns {string} The status value.
   */
  public get statusValue(): string {
    return this.statusId.value?.value ?? this.statusId.value;
  }

  /**
   * Gets the summary form control.
   * @returns {FormControl} The summary form control.
   */
  public get summary(): FormControl {
    return this.profileForm.get('summary') as FormControl;
  }

  /**
   * Gets the clientCompany form control.
   * @returns {FormControl} The clientCompany form control.
   */
  public get clientCompany(): FormControl {
    return this.profileForm.get('clientCompanyId') as FormControl;
  }

  /**
   * Gets the value of the clientCompany form control.
   * @returns {string} The clientCompany value.
   */
  public get clientCompanyValue(): string {
    return typeof this.clientCompany.value === 'string'
      ? this.clientCompany.value
      : this.clientCompany.value.value;
  }

  /**
   * Gets the startDate form control.
   * @returns {FormControl} The startDate form control.
   */
  public get startDate(): FormControl {
    return this.profileForm.get('startDate') as FormControl;
  }

  /**
   * Gets the value of the startDate form control.
   * @returns {string} The startDate value.
   */
  public get startDateValue(): string {
    return this.startDate.value;
  }

  /**
   * Gets the endDate form control.
   * @returns {FormControl} The endDate form control.
   */
  public get endDate(): FormControl {
    return this.profileForm.get('endDate') as FormControl;
  }

  /**
   * Gets the value of the endDate form control.
   * @returns {string} The endDate value.
   */
  public get endDateValue(): string {
    return this.endDate.value;
  }

  /**
   * Checks if the form is valid.
   * @returns {boolean} True if the form is valid, false otherwise.
   */
  public get isFormValid(): boolean {
    if (this.type.value) {
      return this.profileForm.valid;
    } else {
      return Object.keys(this.profileForm.controls)
        .filter(
          controlName =>
            !['startDate', 'endDate', 'clientCompany'].includes(controlName)
        )
        .every(controlName => {
          return this.profileForm.get(controlName)?.valid;
        });
    }
  }

  /**
   * Checks if the form is dirty.
   * @returns {boolean} True if the form is dirty, false otherwise.
   */
  public get isFormDirty(): boolean {
    return this.profileForm.dirty;
  }

  /**
   * Initializes the component and sets up the form.
   * @returns {void}
   */
  ngOnInit(): void {
    this.initForm();
    this.updateValidators();
  }

  /**
   * Initializes the profile form.
   * @returns {void}
   */
  initForm(): void {
    this.profileForm = this.formBuilder.group({
      firstname: [
        null,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(100),
        ],
      ],
      lastname: [
        null,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(100),
        ],
      ],
      birthday: [''],
      gender: [null],
      phoneIso: [{}],
      phone: [null, [Validators.minLength(1), Validators.maxLength(20)]],
      email: [
        null,
        [Validators.required, Validators.email, Validators.maxLength(100)],
      ],
      type: [false],
      statusId: [null, Validators.required],
      officeId: [null, Validators.required],
      summary: [null, Validators.maxLength(1024)],
      teamId: [null],
      experience: [null],
      freelanceSalary: [null],

      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      clientCompanyId: [null, Validators.required],
    });

    if (this.initialData) {
      this.profileForm.patchValue({
        ...this.initialData,
        startDate: this.initialData.startDate
          ? new Date(this.initialData.startDate)
          : '',
        endDate: this.initialData.endDate
          ? new Date(this.initialData.endDate)
          : '',
        type: this.initialData.type,
        clientCompany: this.initialData.clientCompanies
          ? {
              label: this.initialData.clientCompanies.name,
              value: this.initialData.clientCompanies.uuid,
            }
          : null,
      });

      this.profileForm.get('statusId')?.setValue({
        label: this.initialData.status.name,
        value: this.initialData.status.uuid,
      });

      this.profileForm.get('officeId')?.setValue({
        label: this.initialData.office.name,
        value: this.initialData.office.uuid,
      });

      if (this.initialData.phoneIso) {
        this.profileForm
          .get('phoneIso')
          ?.setValue(
            this.utils.findCountryByCode(
              iso31661Codes,
              this.initialData.phoneIso
            )
          );
      }

      if (this.initialData.team) {
        this.profileForm.get('teamId')?.setValue({
          label: this.initialData.team.name,
          value: this.initialData.team.uuid,
        });
      }

      if (this.initialData.clientCompanies) {
        this.profileForm.get('clientCompanyId')?.setValue({
          label: this.initialData.clientCompanies.name,
          value: this.initialData.clientCompanies.uuid,
        });
      }
    }
  }

  /**
   * Handles form submission for creating or updating a profile.
   * @returns {void}
   */
  onSubmit(): void {
    let submissionData = {
      ...this.profileForm.value,
      officeId: this.officeValue,
      statusId: this.statusValue,
      experience: this.experienceValue,
      birthday: this.datePipe.transform(this.birthdayValue, 'yyyy-MM-dd'),
    };
    if (submissionData.phoneIso) {
      submissionData.phoneIso = submissionData.phoneIso.code;
    }

    if (!submissionData.gender) {
      delete submissionData.gender;
    }

    if (!submissionData.phone) {
      delete submissionData.phoneIso;
    }

    if (submissionData.teamId) {
      submissionData.teamId = this.teamValue;
    } else delete submissionData.teamId;

    if (this.type.value === ProfileType.Consultant) {
      submissionData = {
        ...submissionData,
        startDate: this.datePipe.transform(this.startDateValue, 'yyyy-MM-dd'),
        endDate: this.datePipe.transform(this.endDateValue, 'yyyy-MM-dd'),
        type: ProfileType.Consultant,
        clientCompanyId: this.clientCompanyValue,
      };
    } else {
      delete submissionData.endDate;
      delete submissionData.startDate;
      delete submissionData.clientCompanyId;
      submissionData.type = ProfileType.Candidate;
    }

    if (this.initialData) {
      this.profile.updateProfile(
        this.initialData.uuid,
        this.utils.replaceEmptyByNull(submissionData)
      );
    } else {
      this.profile.createProfile(this.utils.filterValues(submissionData));
    }
  }

  /**
   * Searches for client companies based on the input query.
   *
   * @param {AutoCompleteCompleteEvent} event - The autocomplete complete event containing the query.
   * @returns {void}
   */
  searchClientCompanies(event: AutoCompleteCompleteEvent): void {
    this.clientCompaniesService.loadAllClientCompanies({
      limit: 20,
      ...(event.query && { name: event.query }),
    });
  }

  /**
   * Updates the validators for the startDate, endDate, and clientCompanyId form controls
   * based on the value of the type form control.
   *
   * If the type is truthy (assumed to be 'consultant'), the validators for these controls
   * are set to `Validators.required`. Otherwise, the validators are cleared.
   *
   * @returns {void}
   */
  updateValidators(): void {
    const startDateControl = this.startDate;
    const endDateControl = this.endDate;
    const clientCompanyIdControl = this.clientCompany;

    if (this.type.value === 'consultant') {
      startDateControl.setValidators([Validators.required]);
      endDateControl.setValidators([Validators.required]);
      clientCompanyIdControl.setValidators([Validators.required]);
    } else {
      startDateControl.clearValidators();
      endDateControl.clearValidators();
      clientCompanyIdControl.clearValidators();
    }
    startDateControl.updateValueAndValidity();
    endDateControl.updateValueAndValidity();
    clientCompanyIdControl.updateValueAndValidity();
  }
}
