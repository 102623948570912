import { Injectable } from '@angular/core';
import { WsRequest } from '~/app/shared/interfaces/websockets/ws-request.interface';

/**
 * Base class for socket services.
 * Provides a static counter for running sockets.
 * This class is abstract.
 */
@Injectable({
  providedIn: 'root',
})
export abstract class BaseSocketService {
  /**
   * Counter for running sockets.
   * @private
   */
  private static runningSocketsCount = 0;

  /**
   * The WebSocket client instance.
   * @protected
   * @type {WebSocket | null}
   */
  protected wsClient: WebSocket | null = null;

  /**
   * The request data for WebSocket connection.
   * @protected
   * @type {WsRequest | null}
   */
  protected request: WsRequest | null = null;

  /**
   * Checks if any socket is running.
   * @returns {boolean} True if any socket is running, false otherwise.
   */
  public static isAnySocketRunning(): boolean {
    return BaseSocketService.runningSocketsCount > 0;
  }

  /**
   * Increments the running sockets counter.
   * @protected
   * @returns {void}
   */
  protected static incrementRunningSockets(): void {
    BaseSocketService.runningSocketsCount++;
  }

  /**
   * Decrements the running sockets counter.
   * @protected
   * @returns {void}
   */
  protected static decrementRunningSockets(): void {
    BaseSocketService.runningSocketsCount--;
  }

  /**
   * Initializes the WebSocket connection with the provided request.
   * @param {WsRequest} wsRequest - The WebSocket request information.
   * @returns {void}
   */
  initializeConnection(wsRequest: WsRequest): void {
    BaseSocketService.incrementRunningSockets();
    this.request = wsRequest;
    this.wsClient = new WebSocket(this.request.url);
    this.wsClient.onopen = (event: Event): void => {
      this.onOpen(event);
    };
    this.wsClient.onmessage = (message: MessageEvent): void => {
      this.onMessage(message);
    };
    this.wsClient.onerror = (event: Event): void => {
      this.onError(event);
    };
    this.wsClient.onclose = (): void => {
      this.onClose();
    };
  }

  /**
   * Handles the WebSocket onclose event.
   * @protected
   * @param {Event} event - The close event.
   * @returns {void}
   */
  protected onClose(): void {
    BaseSocketService.decrementRunningSockets();
  }

  /**
   * Handles the WebSocket onopen event.
   * @abstract
   * @returns {void}
   */
  abstract onOpen(event: Event): void;

  /**
   * Handles the WebSocket onmessage event.
   * @abstract
   * @param {MessageEvent} message - The incoming message event.
   * @returns {void}
   */
  abstract onMessage(message: MessageEvent): void;

  /**
   * Handles the WebSocket onerror event.
   * @abstract
   * @param {Event} event - The error event.
   * @returns {void}
   */
  abstract onError(event: Event): void;
}
