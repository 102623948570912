import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loginSecureUserValidation } from '@npmicedev/icemodule/lib/validations/users';
import { LoginSecureUser, LoginUser } from '~/app/auth/types/login.types';
import { AppState } from '~/app/core/state/app.state';
import * as LoginActions from '~/app/auth/state/login/login.actions';
import * as AuthActions from '~/app/auth/auth.actions';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { EnumService } from '~/app/core/services/enum.service';

/**
 * Service to handle user authentication including login, logout, and session refresh.
 * Utilizes NgRx store to dispatch authentication related actions and manage state.
 *
 * @Injectable marks this class as a service that can be injected with Angular's dependency injection,
 * making it available throughout the application as a singleton.
 */
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  /**
   * Timeout handle for the refresh token operation.
   * @type {ReturnType<typeof setTimeout>}
   * @private
   */
  private refreshTokenTimeout!: ReturnType<typeof setTimeout>;

  /**
   * Constructs an instance of the class.
   *
   * @param {Store<AppState>} store - Injectable store for managing application state.
   * @param {CustomToastService} toast - Injectable custom toast service for displaying notifications.
   * @param {EnumService} enumService - Injectable service for managing enumerations.
   */
  constructor(
    private store: Store<AppState>,
    private toast: CustomToastService,
    private enumService: EnumService
  ) {}

  /**
   * Performs user login with provided login data. Validation of data is handled
   * using `loginUserValidation` before dispatching the login action.
   * If validation fails, a toast message is displayed with the error details.
   *
   * @param {LoginUser} loginData - The login data containing user credentials.
   * @return {void}
   */
  login(loginData: LoginUser): void {
    /*const result = loginUserValidation.body.safeParse(loginData);

    if (result.success) {
      this.store.dispatch(LoginActions.login({ loginData }));
    } else {
      this.toast.zodErrorToast(result.error);
    }*/ //TO DO
    this.store.dispatch(LoginActions.login({ loginData }));
  }

  /**
   * Performs a secure login with additional security checks. Validation is performed similarly to `login`.
   *
   * @param {LoginSecureUser} loginSecureData - The secure login data including additional security parameters.
   * @return {void}
   */
  secureLogin(loginSecureData: LoginSecureUser): void {
    const result = loginSecureUserValidation.body.safeParse(loginSecureData);

    if (result.success) {
      this.store.dispatch(LoginActions.secureLogin({ loginSecureData }));
    } else {
      this.toast.zodErrorToast(result.error);
    }
  }

  /**
   * Initiates the SAML login process.
   *
   * The company subdomain is extracted from the current hostname and passed to the login action.
   *
   * @returns {void}
   */
  samlLogin(): void {
    const companySubdomain = window.location.hostname.split('.')[0];
    this.store.dispatch(LoginActions.samlLogin({ companySubdomain }));
  }

  /**
   * Logs out the current user by dispatching the logout action.
   * @return {void}
   */
  logout(): void {
    this.store.dispatch(LoginActions.logout());
  }

  /**
   * Dispatches an action to refresh the authentication token.
   * @return {void}
   */
  refreshToken(): void {
    this.store.dispatch(AuthActions.refreshToken());
  }

  /**
   * Stops the automatic token refresh timer to prevent further token refresh requests.
   * @return {void}
   */
  stopRefreshTokenTimer(): void {
    clearTimeout(this.refreshTokenTimeout);
  }

  /**
   * Update the server token.
   * @param {string} newToken - The new token for SSE server.
   * @returns {void}
   */
  updateServerToken(newToken: string): void {
    this.store.dispatch(AuthActions.updateServerToken({ newToken }));
  }

  /**
   * Starts a timer to automatically refresh the token before it expires. The timeout is set
   * based on the token's expiry time fetched from the token payload.
   * @return {void}
   */
  startRefreshTokenTimer(): void {
    /*
    this.store
      .select(selectEnumState)
      .pipe(
        take(1),
        tap(state => {
          const allEmpty = Object.values(state).every(
            list => Array.isArray(list) && list.length === 0
          );
          if (allEmpty) {
            this.enumService.loadCecrlTypes();
            this.enumService.loadOfferStatusTypes();
            this.enumService.loadOfferUnitTypes();
            this.enumService.loadOfferVisibilityTypes();
            this.enumService.loadProfileExperienceTypes();
            this.enumService.loadProfileFileTypes();
            this.enumService.loadProfileTypes();
            this.enumService.loadProfileTimeUnits();
            this.enumService.loadProposalStatusTypes();
            this.enumService.loadReminderTypes();
            this.enumService.loadSaleInformationTypes();
            this.enumService.loadSocialTypes();
            this.enumService.loadGenderTypes();
            this.enumService.loadSkillExperiencesTypes();
          }
        })
      )
      .subscribe();
    this.store
      .select(selectToken)
      .pipe(take(1))
      .subscribe(token => {
        if (token) {
          const jwtToken = JSON.parse(atob(token.split('.')[1]));
          const expires = new Date(jwtToken.exp * 1000);
          const timeout = expires.getTime() - Date.now() - 60 * 1000;

          this.refreshTokenTimeout = setTimeout(() => {
            this.store.dispatch(LoginActions.refreshToken());
          }, timeout);
        }
      });*/
  }
}
