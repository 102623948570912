import { createAction, props } from '@ngrx/store';
import {
  RegisterJobSeeker,
  RegisterUser,
  RegisterUserInCompany,
} from '~/app/auth/types/register.types';
import { AuthUser } from '~/app/shared/interfaces/auth/auth-user.interface';
import { CompanyFormData } from '~/app/shared/interfaces/auth/register/company-form-data.interface';
import { OfficeFormData } from '~/app/shared/interfaces/auth/register/office-form-data.interface';
import { RootUserFormData } from '~/app/shared/interfaces/auth/register/root-user-form-data.interface';

export const jobSeekerRegister = createAction(
  '[Auth] Job Seeker Register',
  props<{ registerData: RegisterJobSeeker }>()
);

export const jobSeekerRegisterSuccess = createAction(
  '[Auth] Job Seeker Register Success'
);

export const jobSeekerRegisterFailure = createAction(
  '[Auth] Job Seeker Register Failure',
  props<{ error: string }>()
);

export const register = createAction(
  '[Auth] Register',
  props<{ registerData: RegisterUser }>()
);
export const registerSuccess = createAction('[Auth] Register Success');
export const registerFailure = createAction(
  '[Auth] Register Failure',
  props<{ error: string }>()
);

export const checkToken = createAction(
  '[Auth] Check Token',
  props<{ token: string }>()
);
export const checkTokenSuccess = createAction(
  '[Auth] Check Token Success',
  props<{ userData: AuthUser }>()
);
export const checkTokenFailure = createAction(
  '[Auth] Check Token Failure',
  props<{ error: string }>()
);

export const checkInvitation = createAction(
  '[Auth] Check invitation',
  props<{ token: string }>()
);
export const checkInvitationSuccess = createAction(
  '[Auth] Check invitation Success',
  props<{ token: string }>()
);
export const checkInvitationFailure = createAction(
  '[Auth] Check invitation Failure',
  props<{ error: string }>()
);

export const userRegister = createAction(
  '[Auth] User Register',
  props<{ token: string; userRegisterData: RegisterUserInCompany }>()
);
export const userRegisterSuccess = createAction(
  '[Auth] User Register Success',
  props<{ userData: AuthUser }>()
);
export const userRegisterFailure = createAction(
  '[Auth] User Register Failure',
  props<{ error: string }>()
);

export const updateCompanyForm = createAction(
  '[Auth] Update Company Form',
  props<{ companyData: CompanyFormData }>()
);

export const updateOfficeForm = createAction(
  '[Auth] Update Office Form',
  props<{ officeData: OfficeFormData }>()
);

export const updateRootUserForm = createAction(
  '[Auth] Update Root User Form',
  props<{ rootUserData: RootUserFormData }>()
);
