<section class="flex flex-column gap-3-5 bg-light-100 custom-width">
  <div
    class="profile-picture-container flex flex-column gap-3-5 text-center cursor-pointer"
    (click)="notYetAvailable()">
    <div class="overlay"></div>
    <img
      src="assets/temp-profile-picture.svg"
      alt="Image"
      i18n-alt
      height="110"
      width="110"
      class="custom profile-picture" />
  </div>
  <ng-container *ngIf="currentUser$ | async as currentUser; else notConnected">
    <a class="text-sm color-dark-70 m-0 text-center no-underline"
      >{{ currentUser.user.firstname }} {{ currentUser.user.lastname }}</a
    >
  </ng-container>
  <ng-template #notConnected>
    <a class="text-sm color-dark-70 m-0 text-center no-underline"
      >Anna de Armas</a
    >
  </ng-template>
  <div
    class="absolute bottom-0 right-0 mr-2 mb-2"
    (click)="$event.stopPropagation()">
    <ice-button
      [icon]="!isLightTheme ? 'pi pi-sun' : 'pi pi-moon'"
      (click)="onThemeToggle()"
      class="theme-toggle-button">
    </ice-button>
  </div>
  <p-divider></p-divider>
  <div class="flex flex-column gap-3-5 w-full">
    <ice-button
      [text]="true"
      label="My Settings"
      (click)="goToProfilePage()"></ice-button>
    <ice-button (click)="logout()" [text]="true" label="Log out"></ice-button>
  </div>
</section>
