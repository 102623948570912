import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { RegisterService } from '~/app/auth/services/register.service';
import { LoginFormComponent } from '~/app/shared/components/login-form/login-form.component';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { DialogService } from '~/app/shared/services/dialog.service';

/**
 * Component for handling the registration form for job seekers.
 * This component is a template.
 */
@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrl: './register-form.component.scss',
})
export class RegisterFormComponent implements OnInit {
  /**
   * Flag indicating if the form has been submitted.
   * @type {boolean}
   * @default false
   */
  isFormSubmitted = false;

  /**
   * FormGroup instance for the job seeker registration form.
   * @type {FormGroup}
   */
  jobSeekerForm!: FormGroup;

  /**
   * Array of image paths for the registration form.
   * @type {string[]}
   */
  images = [
    'assets/fancy-deco/register-freelance-fancydeco.svg',
    'assets/fancy-deco/register-freelance-2-fancydeco.svg',
  ];

  /**
   * Constructs the RegisterFormComponent with necessary dependencies.
   *
   * @param {FormBuilder} formBuilder FormBuilder for creating form controls and groups.
   * @param {RegisterService} registerService RegisterService for handling registration logic.
   * @param {DialogService} dialog DialogService for displaying dialog windows.
   * @param {CustomToastService} toast CustomToastService for displaying toast notifications.
   */
  constructor(
    private formBuilder: FormBuilder,
    private registerService: RegisterService,
    private dialog: DialogService,
    private toast: CustomToastService
  ) {}

  /**
   * Getter for the firstname form control.
   * @returns {FormControl} The firstname form control.
   */
  get firstname(): FormControl {
    return this.jobSeekerForm.get('firstname') as FormControl;
  }

  /**
   * Getter for the lastname form control.
   * @returns {FormControl} The lastname form control.
   */
  get lastname(): FormControl {
    return this.jobSeekerForm.get('lastname') as FormControl;
  }

  /**
   * Getter for the userEmail form control.
   * @returns {FormControl} The userEmail form control.
   */
  get userEmail(): FormControl {
    return this.jobSeekerForm.get('userEmail') as FormControl;
  }

  /**
   * Getter for the password form control.
   * @returns {FormControl} The password form control.
   */
  get password(): FormControl {
    return this.jobSeekerForm.get('password') as FormControl;
  }

  /**
   * Getter for the confirmPassword form control.
   * @returns {FormControl} The confirmPassword form control.
   */
  get confirmPassword(): FormControl {
    return this.jobSeekerForm.get('confirmPassword') as FormControl;
  }

  /**
   * Getter for the termsAndConditions form control.
   * @returns {FormControl} The termsAndConditions form control.
   */
  get termsAndConditions(): FormControl {
    return this.jobSeekerForm.get('termsAndConditions') as FormControl;
  }

  /**
   * Initializes the job seeker registration form.
   * @return {void}
   */
  ngOnInit(): void {
    this.initForm();
  }

  /**
   * Initializes the job seeker registration form.
   * @return {void}
   */
  initForm(): void {
    this.jobSeekerForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      userEmail: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: [
        '',
        [Validators.required, this.confirmPasswordValidator.bind(this)],
      ],
      termsAndConditions: [false, Validators.requiredTrue],
    });
  }

  /**
   * Handles the submission of the jobseeker registration form.
   * If the form is valid, the data is sent to the RegisterService for processing.
   * Why are there this lot of userEmails???? Because a jobseeker is a company with only one user.
   * So to register a company, we need to provide the company name, office name, subdomain, first name, last name, email and password but for a jobseeker
   * we do not need to provide the company name, office name and subdomain. So we can use the same form for both because the email is unique.
   * @return {void}
   */
  onSubmit(): void {
    //this.isFormSubmitted = true;
    /*const registerData = {
      companyName: this.jobSeekerForm.value.userEmail,
      officeName: this.jobSeekerForm.value.userEmail,
      firstname: this.jobSeekerForm.value.firstname,
      lastname: this.jobSeekerForm.value.lastname,
      userEmail: this.jobSeekerForm.value.userEmail,
      password: this.jobSeekerForm.value.password,
    };*/
    this.toast.warningToast('Be patient...', 'Register is not available yet');
    //this.registerService.jobSeekerRegister(registerData);
  }

  /**
   * Custom validator for confirming the password.
   * @param {FormControl} control The form control for the confirmPassword field.
   * @returns {object} An object with a boolean indicating if the password is confirmed.
   */
  confirmPasswordValidator(
    control: FormControl
  ): { [s: string]: boolean } | null {
    if (
      this.jobSeekerForm &&
      control.value !== this.jobSeekerForm.get('password')?.value
    ) {
      return { passwordMismatch: true };
    }
    return null;
  }

  /**
   * Handles the change event for the terms and conditions checkbox.
   * If the checkbox is checked, the termsAndConditions form control is set to null.
   * If the checkbox is unchecked, the termsAndConditions form control is set to required.
   * @param {CheckboxChangeEvent} event The change event for the checkbox.
   * @return {void}
   */
  onTermsAndConditionsChange(event: CheckboxChangeEvent): void {
    if (event.checked) {
      this.termsAndConditions.setErrors(null);
    } else {
      this.termsAndConditions.setErrors({ required: true });
    }
  }

  /**
   * Toggles the dialog service to display the login form.
   * @return {void}
   */
  switchToLogin(): void {
    this.dialog.toggleDialog();
    this.dialog.loadComponent(LoginFormComponent, {
      centered: 'centered',
      showBackdrop: true,
      closable: true,
    });
    this.dialog.toggleDialog();
  }
}
