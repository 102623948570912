<!--eslint-disable @angular-eslint/template/cyclomatic-complexity-->

<div
  #container
  class="pb-3 relative w-full flex flex-column gap-2"
  [tabIndex]="0"
  aria-label="Autocomplete input"
  aria-haspopup="listbox"
  aria-owns="suggestions"
  aria-expanded="isOpen">
  <!-- Label -->
  <label class="color-dark-80 text-sm mx-0">
    {{ label }} <span *ngIf="required" class="color-danger-100">*</span>
  </label>

  <!-- Input -->
  <input
    #input
    type="text"
    [value]="multiple ? '' : $any(value)?.label ?? ''"
    (focus)="onFocusInput()"
    placeholder="{{ placeholder }}"
    (input)="onComplete($event)"
    aria-autocomplete="list"
    aria-controls="suggestions" />

  <!-- Suggestions -->
  <ul
    id="suggestions"
    [@fadeIn]="isOpenAnim"
    #suggestions
    [ngClass]="{
      hidden: !isOpen,
      visible: isOpen,
    }"
    role="listbox">
    <ng-container
      *ngIf="
        (filteredSuggestions$ | async)?.length === 0;
        else suggestionsList
      ">
      <li class="placeholder" role="option" tabindex="0">
        <div>No results found</div>
      </li>
    </ng-container>
    <ng-template #suggestionsList>
      <li
        #suggestionItem
        *ngFor="let item of filteredSuggestions$ | async"
        [ngClass]="{
          selected: multiple
            ? (item.value | isIn: formControl.value ?? [] : 'value')
            : item.value === formControl.value,
        }"
        role="option"
        tabindex="0"
        (click)="selectSuggestion(item)">
        <ng-container [ngSwitch]="iceAutocompleteTemplate">
          <ng-container *ngSwitchCase="templateEnum.DEFAULT">
            <div>{{ item.label }}</div>
          </ng-container>

          <ng-container *ngSwitchCase="templateEnum.STATUS">
            <div class="flex align-items-center gap-2">
              <div
                class="border-circle w-1rem h-1rem"
                [ngStyle]="{ 'background-color': item.color }"></div>
              <div>{{ item.label }}</div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="templateEnum.SOCIAL">
            <div class="flex align-items-center gap-2">
              <i [class]="'w-1rem pi pi-' + item.value"></i>
              <div>{{ item.label }}</div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="templateEnum.TAG">
            <div class="flex align-items-center gap-2">
              <div
                class="pi pi-circle-fill"
                [ngStyle]="{ color: item.color }"></div>
              <div>{{ item.label }}</div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="templateEnum.USER">
            <div class="flex gap-2 align-items-center border-round">
              <p-avatar
                shape="circle"
                i18n-shape
                [size]="'normal'"
                [style]="{ backgroundColor: item.color }"
                [label]="
                  (item.firstname?.toUpperCase()?.charAt(0) ?? '?') +
                  (item.lastname?.toUpperCase()?.charAt(0) ?? '?')
                "></p-avatar>
              <span class="text-900">{{
                item.firstname + ' ' + item.lastname | titlecase
              }}</span>
            </div>
          </ng-container>
        </ng-container>
      </li>
    </ng-template>
  </ul>
</div>
