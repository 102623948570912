import { Injectable, Type, ViewContainerRef } from '@angular/core';
import { IceDialog } from '~/app/shared/interfaces/shared/ice-components/ice-dialog';

/**
 * Service to manage the dialog's state, content, and visibility within the application.
 * It provides methods to dynamically load components into the dialog, manage titles,
 * and toggle visibility.
 *
 * @Injectable indicates that this service can be injected into other components and services.
 */
@Injectable({
  providedIn: 'root',
})
export class DialogService {
  /**
   * Position of the dialog.
   * @private
   */
  private top = '0rem';

  /**
   * Position of the dialog.
   * @private
   */
  private right = '0rem';

  /**
   * Controls whether the dialog can be closed by the user.
   * @private
   */
  private isClosable = true;

  /**
   * Reference to the ViewContainer where modal content will be attached dynamically.
   * @private
   */
  private viewContainerRef: ViewContainerRef | null = null;

  /**
   * Controls whether the modal is displayed as a blocking modal.
   * @private
   */
  private showBackdrop = true;

  /**
   * Current visibility state of the modal.
   * @private
   */
  private isVisible = false;

  /**
   * Centered of the dialog.
   * @private
   */
  private centered!: boolean;

  /**
   * Returns the current visibility state of the dialog.
   * @returns {boolean} True if the dialog is visible, otherwise false.
   */
  get dialogVisible(): boolean {
    return this.isVisible;
  }

  /**
   * Returns the position of the dialog.
   * @returns {{ top: string, right: string }} The position of the dialog.
   */
  get dialogPosition(): { top: string; right: string } {
    return { top: this.top, right: this.right };
  }

  /**
   * Returns the closable boolean of the dialog.
   * @returns {boolean} The closable variable.
   */
  get isDialogClosable(): boolean {
    return this.isClosable;
  }

  /**
   * Returns the centered boolean of the dialog.
   * @returns {boolean} The centered variable.
   */
  get isCentered(): boolean {
    return this.centered;
  }

  get isBackdropVisible(): boolean {
    return this.showBackdrop;
  }

  /**
   * Sets the ViewContainerRef for modal content. This is the anchor point for inserting dynamic components.
   * @param {ViewContainerRef} ref - The ViewContainerRef to attach dynamic components to.
   * @return {void}
   */
  setViewContainerRef(ref: ViewContainerRef): void {
    this.viewContainerRef = ref;
  }

  /**
   * Sets the position of the dialog.
   * @param {string} top - The top position of the dialog.
   * @param {string} right - The right position of the dialog.
   * @return {void}
   */
  setPosition(top: string, right: string): void {
    this.top = top;
    this.right = right;
  }

  /**
   * Sets the closable boolean of the dialog.
   * @param {boolean} closable - The closable variable to set for the dialog.
   * @return {void}
   */
  setDialogClosable(closable: boolean): void {
    this.isClosable = closable;
  }

  /**
   * Sets the visibility of the modal and clears the title and subtitle if it is being hidden.
   * @param {boolean} visible - Specifies the visibility state of the modal.
   * @return {void}
   */
  setDialogVisibility(visible: boolean): void {
    this.isVisible = visible;
  }

  /**
   * Sets the centered boolean of the dialog.
   * @param {boolean} centered - The centered varialble to set for the dialog.
   * @return {void}
   */
  setCentered(centered: boolean): void {
    this.centered = centered;
  }

  /**
   * Sets the backdrop visibility of the dialog.
   * @param {boolean} show - The boolean value to set the backdrop visibility.
   * @return {void}
   */
  setShowBackdrop(show: boolean): void {
    this.showBackdrop = show;
  }

  /**
   * Toggles the visibility of the dialog.
   * @return {void}
   */
  toggleDialog(): void {
    this.isVisible = !this.isVisible;
  }

  /**
   * Dynamically loads a component into the dialog's ViewContainerRef with optional inputs, title, and subtitle.
   * @param {Type<any>} component - The component type to load.
   * @param {IceDialog} options - The options for the dialog.
   * @return {void}
   */
  loadComponent(component: Type<any>, options: IceDialog = {}): void {
    const {
      inputs,
      centered = 'centered',
      showBackdrop,
      closable,
      refElement,
    } = options;

    this.setPosition('0rem', '0rem');
    this.setCentered(false);

    if (centered === 'centered') {
      this.setCentered(true);
      this.setPosition('0rem', '0rem');
      //eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (centered === 'not-centered') {
      this.setCentered(false);
    } else if (centered === 'onRef' && refElement && this.viewContainerRef) {
      this.viewContainerRef.clear();
      const componentRef = this.viewContainerRef.createComponent(component);

      for (const key in inputs) {
        if (inputs.hasOwnProperty(key)) {
          componentRef.instance[key] = inputs[key];
        }
      }
      // Wait for the view to render
      setTimeout(() => {
        const dialogElement = componentRef.location
          .nativeElement as HTMLElement;
        const dialogRect = dialogElement.getBoundingClientRect();
        const refRect = refElement.getBoundingClientRect();

        const top = refRect.top + refRect.height + 14;
        const right = -refRect.right + refRect.width + dialogRect.width + 42;
        this.setPosition(`${top}px`, `${right}px`);
      });
    }

    if (showBackdrop !== undefined) {
      this.setShowBackdrop(showBackdrop);
    }
    if (closable !== undefined) {
      this.setDialogClosable(closable);
    }

    if (centered !== 'onRef' && this.viewContainerRef) {
      this.viewContainerRef.clear();
      const componentRef = this.viewContainerRef.createComponent(component);

      for (const key in inputs) {
        if (inputs.hasOwnProperty(key)) {
          componentRef.instance[key] = inputs[key];
        }
      }
    }
  }
}
