<div *ngIf="label && unit" class="text-xs color-dark-100">
  {{ label }}<span class="color-dark-70"> /{{ unit }}</span>
</div>

<div style="height: 100px" class="w-full pt-4 flex align-items-end">
  <div
    *ngFor="let item of distribution$ | async; let i = index"
    class="inline-block px-1"
    [@barHeight]
    [style]="{ height: item.nbProfiles + '%', width: '5%' }">
    <div
      class="border-round h-full w-full bg-primary-60 histo-item"
      (mouseover)="showTooltips[i] = true"
      (mouseout)="showTooltips[i] = false">
      <div
        class="absolute pointer-events-none bottom-100 p-1 mb-1 border-round-md shadow color-light-100 bg-primary-90 text-bold-xs"
        *ngIf="showTooltips[i]">
        {{ item.average }}
      </div>
    </div>
  </div>
</div>
<div>
  <div class="slider-container mt-5">
    <input
      id="fromSlider"
      type="range"
      [value]="value[0]"
      [defaultValue]="min"
      (input)="patchValue($event)"
      [min]="min"
      [max]="max | async" />
    <input
      #slider
      id="toSlider"
      type="range"
      [value]="value[1]"
      (input)="patchValue($event)"
      [defaultValue]="max"
      [min]="min"
      [max]="max | async" />

    <div #fromTag id="fromTag" class="absolute bottom-100">{{ value[0] }}</div>
    <div #toTag id="toTag" class="absolute top-50">{{ value[1] }}</div>
  </div>
</div>
