import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { TermsComponent } from './pages/terms/terms.component';
import { JobsComponent } from '~/app/landing/pages/landing/components/jobs/jobs.component';
import { TestimonialsComponent } from '~/app/landing/pages/landing/components/testimonials/testimonials.component';
import { StorytellingComponent } from '~/app/landing/pages/landing/components/storytelling/storytelling.component';
import { FeaturesComponent } from '~/app/landing/pages/landing/components/features/features.component';
import { HeaderComponent } from '~/app/landing/pages/landing/components/header/header.component';
import { HeroComponent } from '~/app/landing/pages/landing/components/hero/hero.component';
import { LandingComponent } from '~/app/landing/pages/landing/landing.component';
import { LandingRoutingModule } from '~/app/landing/landing-routing.module';
import { PrimengModule } from '~/app/primeng/primeng.module';
import { SharedModule } from '~/app/shared/shared.module';

@NgModule({
  declarations: [
    LandingComponent,
    HeaderComponent,
    HeroComponent,
    FeaturesComponent,
    JobsComponent,
    TestimonialsComponent,
    StorytellingComponent,
    TermsComponent,
  ],
  imports: [
    CommonModule,
    LandingRoutingModule,
    PrimengModule,
    NgOptimizedImage,
    SharedModule,
  ],
  exports: [HeaderComponent],
})
export class LandingModule {}
