import {
  animate,
  AnimationTriggerMetadata,
  style,
  transition,
  trigger,
} from '@angular/animations';

/**
 * Animation trigger for fading in an element.
 *
 * @const {AnimationTriggerMetadata} fadeIn
 * @transition Transition from false to true.
 * @style Initial style with opacity 0.
 * @animate Animates the element to opacity 1 over 300ms with ease-in timing.
 */
export const fadeIn: AnimationTriggerMetadata = trigger('fadeIn', [
  transition('false => true', [
    style({ opacity: 0 }),
    animate('150ms ease-in', style({ opacity: 1 })),
  ]),
  transition('true => false', [
    style({ opacity: 1 }),
    animate('150ms ease-in', style({ opacity: 0 })),
  ]),
]);
