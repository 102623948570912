import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Progress } from '~/app/shared/interfaces/websockets/progress.interface';
import { ProgressToastService } from '~/app/shared/services/progress-toast.service';

/**
 * Component for displaying a progress toast.
 */
@Component({
  selector: 'ice-progress-toast',
  templateUrl: './ice-progress-toast.component.html',
  styleUrl: './ice-progress-toast.component.scss',
})
export class IceProgressToastComponent {
  /**
   * The progress information.
   * @type {Observable<Progress | null>}
   * @memberof IceProgressToastComponent
   */
  progress!: Observable<Progress | null>;

  /**
   * The visibility of the progress toast.
   *
   * @type {Observable<boolean>}
   * @memberof IceProgressToastComponent
   */
  visible: Observable<boolean>;

  /**
   * Creates an instance of IceProgressToastComponent.
   * @param {ProgressToastService} progressToastService - The progress toast service.
   */
  constructor(private progressToastService: ProgressToastService) {
    this.progress = this.progressToastService.progress$;
    this.visible = this.progressToastService.isVisible$;
  }

  /**
   * Handles the close event of the progress toast.
   *
   * @returns {void}
   */
  onClose(): void {
    this.progressToastService.toggleToast();
  }
}
