import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { registerUserInCompanyValidation } from '@npmicedev/icemodule/lib/validations/companies';
import { registerUserValidation } from '@npmicedev/icemodule/lib/validations/users';
import {
  RegisterJobSeeker,
  RegisterUser,
  RegisterUserInCompany,
} from '~/app/auth/types/register.types';
import { AppState } from '~/app/core/state/app.state';
import * as RegisterActions from '~/app/auth/state/register/register.actions';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';

/**
 * Service responsible for user registration.
 * Utilizes NgRx store to dispatch actions related to user registration.
 *
 * @class
 * @Injectable Marks the class as an injectable service, allowing it to be provided and injected throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  /**
   * Constructs an instance of the RegisterService.
   *
   * @constructor
   * @param {Store<AppState>} store - The NgRx store for managing application state.
   *   It allows the service to dispatch registration actions and select pieces of state.
   * @param {CustomToastService} toast - A service for displaying toast notifications.
   *   This is used within the service to show error messages when user validation fails.
   */
  constructor(
    private store: Store<AppState>,
    private toast: CustomToastService
  ) {}

  /**
   * Register a jobseeker with the provided registration data.
   *
   * @param {RegisterUser} registerData - The registration data containing user information.
   * @returns {void}
   */
  jobSeekerRegister(registerData: RegisterJobSeeker): void {
    //TO DO - check if registerData is valid

    this.store.dispatch(RegisterActions.jobSeekerRegister({ registerData }));
  }

  /**
   * Registers a user with the provided registration data.
   *
   * @param {RegisterUser} registerData - The registration data containing user information.
   * @returns {void}
   */
  register(registerData: RegisterUser): void {
    const result = registerUserValidation.body.safeParse(registerData);

    if (result.success) {
      this.store.dispatch(RegisterActions.register({ registerData }));
    } else {
      this.toast.zodErrorToast(result.error);
    }
  }

  /**
   * Registers a user in a company using the provided registration data and token.
   *
   * @param {string} token - The token associated with the company registration.
   * @param {RegisterUserInCompany} userRegisterData - The registration data containing user and company information.
   * @returns {void}
   */
  userRegister(token: string, userRegisterData: RegisterUserInCompany): void {
    const result =
      registerUserInCompanyValidation.body.safeParse(userRegisterData);

    if (result.success) {
      this.store.dispatch(
        RegisterActions.userRegister({ token, userRegisterData })
      );
    } else {
      this.toast.zodErrorToast(result.error);
    }
  }

  /**
   * Checks the validity of a registration token.
   *
   * @param {string} token - The registration token to be checked.
   * @returns {void}
   */
  checkToken(token: string): void {
    this.store.dispatch(RegisterActions.checkToken({ token }));
  }

  /**
   * Checks the validity of an invitation token.
   *
   * @param {string} token - The invitation token to be checked.
   * @returns {void}
   */
  checkInvitation(token: string): void {
    this.store.dispatch(RegisterActions.checkInvitation({ token }));
  }
}
