import { animate, style, transition, trigger } from '@angular/animations';

/**
 * Animation trigger for growing the height of an element when it enters the DOM.
 *
 * This animation is defined using Angular's animation API and is triggered when an element is added to the DOM.
 * It animates the height of the element from 0 to its natural height over a duration of 0.5 seconds with an ease timing function.
 *
 * @constant heightGrowEnter
 * @type {AnimationTriggerMetadata}
 */
export const heightGrowEnter = trigger('barHeight', [
  transition(':enter', [
    style({ height: 0 }), // Initial height set to 0
    animate('0.5s ease', style({ height: '*' })), // Animate to full height
  ]),
]);
