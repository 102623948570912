<div
  *ngIf="visible | async as isVisible"
  class="absolute bottom-0 w-full flex justify-content-end">
  <div
    class="container border-round mb-3-5 mr-3-5 w-20rem py-2-5 px-3-5 flex flex-column gap-3-5 border-dark-10">
    <p class="m-0 color-light-100-forever text-base">Analyzing your file</p>
    <div class="flex flex-column gap-2-5">
      <p-progressBar
        *ngIf="progress | async as progress"
        [value]="progress.progress * 100"
        [showValue]="false"
        [style]="{ height: '6px' }"></p-progressBar>
      <p
        *ngIf="progress | async as progress"
        class="m-0 color-light-100-forever text-sm">
        {{ progress.content }}
      </p>
    </div>
  </div>
</div>
