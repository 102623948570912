import { Component } from '@angular/core';
import { termsConst } from '~/app/shared/const/terms.const';

/**
 * Component for displaying the terms and conditions of the application.
 *
 * @Component
 * @selector 'app-terms' - The CSS selector that identifies this component in a template.
 * @templateUrl './terms.component.html' - The path to the HTML template for this component.
 * @styleUrls ['./terms.component.scss'] - The paths to the styles for this component.
 */
@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrl: './terms.component.scss',
})
export class TermsComponent {
  /**
   * The terms and conditions of the application.
   * @type {object}
   * @default termsConst
   */
  termsConst = termsConst;
}
