import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectCurrentUser } from '~/app/auth/auth.selectors';
import { LoginService } from '~/app/auth/services/login.service';
import { ThemeService } from '~/app/core/services/theme.service';
import { MainState } from '~/app/main/main.state';
import { AuthUser } from '~/app/shared/interfaces/auth/auth-user.interface';
import { CustomToastService } from '~/app/shared/services/custom-toast.service';
import { DialogService } from '~/app/shared/services/dialog.service';

/**
 * Component responsible for rendering the account menu in the application's header.
 *
 * @Component decorator that specifies the metadata for the component.
 * @selector 'app-account-menu' - CSS selector that defines the component.
 * @templateUrl './account-menu.component.html' - Path to the HTML template for this component.
 * @styleUrls ['./account-menu.component.scss'] - Path to the styles for this component.
 */
@Component({
  selector: 'app-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrl: './account-menu.component.scss',
})
export class AccountMenuComponent {
  /**
   * A boolean flag to determine the current theme setting.
   *
   * When set to true, the light theme is applied.
   * When set to false, the dark theme is applied.
   * @type {boolean}
   */
  public isLightTheme = true;

  /**
   * An observable representing the currently logged-in user.
   *
   * @type {Observable<AuthUser | null>}
   */
  currentUser$: Observable<AuthUser | null>;

  /**
   * A boolean flag to determine if the user is hovering over the account menu.
   *
   * When set to true, the account menu is displayed.
   * When set to false, the account menu is hidden.
   * @type {boolean}
   */
  hover!: boolean;

  /**
   * Constructor that injects the ThemeService, LoginService, and ToastService.
   * @param {ThemeService} theme - The service responsible for managing the application's theme.
   * @param {LoginService} loginService - The service responsible for managing the user's authentication state.
   * @param {CustomToastService} toastService - The service responsible for displaying toast notifications.
   * @param {Router} router - The Angular router service.
   * @param {DialogService} dialog - The service responsible for displaying dialog windows.
   * @param {NotYetAvailableService} notYetAvailable - The service responsible for displaying a toast message when a feature is not yet available.
   * @param {Store<MainState>} store - The store to manage state.
   */
  constructor(
    private theme: ThemeService,
    private loginService: LoginService,
    private toastService: CustomToastService,
    private router: Router,
    private dialog: DialogService,
    private store: Store<MainState>
  ) {
    this.isLightTheme = !this.theme.isDarkThemeActive();
    this.currentUser$ = this.store.select(selectCurrentUser);
  }

  /**
   * Logs the user out of the application using the LoginService.
   * @return {void}
   */
  logout(): void {
    this.loginService.logout();
  }

  /**
   * Navigates the user to the profile page.
   * @return {void}
   */
  goToProfilePage(): void {
    const hostname = window.location.hostname;
    if (hostname.includes('.')) {
      void this.router.navigate(['settings/my-settings']);
    } else {
      void this.router.navigate(['my-settings']);
      this.dialog.toggleDialog();
    }
  }

  /**
   * Toggles the application's theme between light and dark.
   * @return {void}
   */
  onThemeToggle(): void {
    this.theme.toggleTheme();
  }

  /**
   * Not yet implemented.
   * @return {void}
   */
  notYetAvailable(): void {
    this.toastService.warningToast('Be patient...', 'Not yet available');
  }
}
