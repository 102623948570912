import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from '~/app/shared/services/local-storage.service';

/**
 * Service to manage and apply themes across the application. It interacts with local storage
 * to persist theme preferences and supports dynamic switching of themes based on user preferences
 * or system settings.
 *
 * @Injectable Indicates that this service can be injected into other components and services.
 * @providedIn 'root' Specifies that Angular should provide this service in the root injector,
 * making it available throughout the app.
 */
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  /**
   * A BehaviorSubject that holds the current state of the dark theme setting.
   * Emits a boolean value where `true` represents that the dark theme is enabled,
   * and `false` represents that the dark theme is disabled.
   *
   * Initialized with `false` to indicate that the dark theme is disabled by default.
   * @type {boolean}
   */
  public isDarkThemeSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  /**
   * Key used to store and retrieve the theme setting from local storage.
   * @type {string}
   */
  private readonly themeKey: string = 'app-theme';

  /**
   * Constructs the ThemeService with necessary dependencies.
   * @param {LocalStorageService} localStorage - Injected to handle storage and retrieval of theme preferences in
   * local storage.
   */
  constructor(private localStorage: LocalStorageService) {}

  /**
   * Loads the theme from local storage or determines the theme based on the user's preferred color scheme.
   * If no theme is set in local storage, it sets the theme based on the system's preferred color scheme.
   * @return {void}
   */
  initTheme(): void {
    const savedTheme = localStorage.getItem(this.themeKey);
    const themeToApply = savedTheme === 'dark' ? 'dark' : 'light';
    this.changeTheme(themeToApply);
    document.body.setAttribute(this.themeKey, themeToApply);
    if (!savedTheme) {
      localStorage.setItem(this.themeKey, 'light');
    }
  }

  /**
   * Changes the theme of the application by updating the theme CSS file link in the document head.
   * This allows the theme to be applied immediately without reloading the page.
   * @param {string} theme The name of the new theme to apply.
   * @return {void}
   */
  changeTheme(theme: string): void {
    //this.localStorage.saveItem(this.themeKey, theme);
    const linkElement = document.getElementById(
      this.themeKey
    ) as HTMLLinkElement;
    linkElement.href = `aura-${theme}-noir-custom.css`;
    this.isDarkThemeSubject.next(theme.includes('dark'));
  }

  /**
   * Checks if the currently active theme is a dark theme.
   * @returns {boolean} True if the current theme is dark, otherwise false.
   */
  isDarkThemeActive(): boolean {
    return this.isDarkThemeSubject.value;
  }

  /**
   * Toggles the theme between light and dark.
   * @return {void}
   */
  toggleTheme(): void {
    const currentTheme = localStorage.getItem(this.themeKey) ?? 'light';
    const newTheme = currentTheme === 'light' ? 'dark' : 'light';
    document.body.setAttribute(this.themeKey, newTheme);
    localStorage.setItem(this.themeKey, newTheme);
    this.changeTheme(newTheme);
  }

  /**
   * Determines the user's preferred color scheme from the system settings.
   * @returns {string} The default theme based on the system's preference for a color scheme.
   */
  private getPreferredColorScheme(): string {
    return window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'aura-dark-noir-custom'
      : 'aura-light-noir-custom';
  }
}
